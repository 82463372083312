import { TextField, InputLabel, FormLabel, InputAdornment } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Grid } from "@mui/material";

const styles = (theme) => ({
  inputLabel: {
    lineHeight: "1.5",
    color: "#8596a9",
    fontSize: "0.9rem",
    marginBottom: "0.2rem",
  },
  disabled:{
    backgroundColor:"#C7E4FD !important"
  }
});
const useStyles = makeStyles(styles);

const TextFieldWithLabel = (props) => {
  const classes = useStyles();
  const inputProps = props.inputProps || { max: 99, min: 1 };
  return (
    <>
      {props.horizontalView ? (
        <>
          <Grid container>
            <Grid item xs>
              {props.label ? (
                <FormLabel id="demo-row-radio-buttons-group-label">
                  {props.label}
                </FormLabel>
              ) : null}{" "}
              &nbsp;&nbsp;&nbsp;
            </Grid>
            <Grid item xs>
                <TextField
                  InputProps={{ sx: { height: 40, width: props.width,fontSize:"13px",fontFamily:"-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen-Sans, Ubuntu, Cantarell, Helvetica Neue, sans-serif" }, endAdornment: props.endAdornment, inputProps: inputProps  }}
                  hiddenLabel
                  size="small"
                  id="filled-hidden-label-normal"
                  variant="outlined"
                  value={props.value}
                  onChange={props.onChange}
                  onBlur={props.handleOnBlur}
                  defaultValue={props.defaultValue}
                  name={props.name}
                  type={props.type}
                  disabled={props.disabled}
                  // {...props}
                />
            </Grid>
          </Grid>
        </>
      ) : (
        <>
          {
      props.label ? ( <>
          <InputLabel
              className={classes.inputLabel}
              required={props.required}
              placeholder={props?.label}
            >
              <span 
              title={props.label}>
                {props.label}
              </span>
            </InputLabel>
          </> ) : null 
          }
        
        <TextField
          InputProps={{ sx: { height: 30, width: props.width,fontSize:"13px",fontFamily:"-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen-Sans, Ubuntu, Cantarell, Helvetica Neue, sans-serif" }, 
          endAdornment: props.endAdornment, inputProps: inputProps,
            startAdornment: props.startAdornment,
            }}
          hiddenLabel
          size="small"
          id="filled-hidden-label-normal"
          variant="outlined"
          value={props.value}
          onChange={props.onChange}
          onBlur={props.handleOnBlur}
          defaultValue={props.defaultValue}
          name={props.name}
          type={props.type}
          disabled={props.disabled}
          className={props.className}

          // {...props}
        />
        </>
      )}
    </>
  );
};

export default TextFieldWithLabel;
