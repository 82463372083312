import React from 'react';
import { experimentalStyled as styled } from '@mui/material/styles';
import { Paper,Grid } from '@mui/material';
import Select from './Select';

const Item = styled(Paper)(({ theme, width }) => ({
  ...theme.typography.body2,
  textAlign: 'left',
  boxShadow: 'none',
  background: 'transparent',
  width,
  color: theme.palette.text.secondary,
}));

const SelectRenderer = (props) => {
  const {
    type,
    filterLabel,
    isMandatory,
    isMulti,
    placeholder,
    updateSelected,
    options,
    selectedItems,
    width,
    isDisabled,
    isIconRequired
  } = props;

  return (
    <div>
      {props.horizontalView  ? (
        <>
          <Grid container>
            <Grid item xs={4} sm={4} md={4}>
            <label>
              {filterLabel}
              {isMandatory ? <span style={{ color: 'red' }}> * </span> : null}
            </label>
            </Grid>
            <Grid item xs={8} sm={8} md={8}>
            <Select
          type={type}
          placeholder={placeholder}
          isMulti={isMulti}
          initialData={options}
          selectedOptions={selectedItems}
          reset={false}
          dependency={selectedItems}
          updateSelected={updateSelected}
          onDropdownOpen={() => console.log('Ok')}
          forceApiCall={false}
          isDisabled={isDisabled}
          isIconRequired={isIconRequired}
        />
            </Grid>
          </Grid>
        </>
      ) : (
        <>
      <Item className='filterGroup'
       width={width}
       >
        <label>
          {filterLabel}
          {isMandatory ? <span style={{ color: 'red' }}> * </span> : null}
        </label>
        <Select
          type={type}
          placeholder={placeholder}
          isMulti={isMulti}
          initialData={options}
          selectedOptions={selectedItems}
          reset={false}
          dependency={selectedItems}
          updateSelected={updateSelected}
          onDropdownOpen={() => console.log('Ok')}
          forceApiCall={false}
          isDisabled={isDisabled}
          isIconRequired={isIconRequired}
        />
      </Item>
      </>)}
    </div>
  );
};

export default SelectRenderer;
