import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

function DropdownCellRenderer(props) {
	let val = props.value || "";
	let varInArr = val.split(",");
	const [value, setValue] = useState(
		props.data.default_selected_value || varInArr[0],
	);
	const dispatch = useDispatch();
	const { tableInfo } = useSelector((state) => state.home);

	const onValueChange = (event) => {
		setValue(event.target.value);
		if (props.action_type) {
			switch (props.action_type) {
				case "COLUMNS_DATA_UPDATE":
					const tableData = tableInfo[`${props.parent_table_key}`] || [];
					if (tableData.length > 0) {
						tableData.forEach((element, index) => {
							if (props.rowIndex === index) {
								props.columnsList.forEach((colElement) => {
									element[`${colElement}`] = props.changeValueTo;
									element[`default_selected_value`] = event.target.value;
								});
							}
						});
						let params = {};
						params[`${props.parent_table_key}`] = tableData;
						dispatch({
							type: "TABLE_DATA",
							payload: params,
						});
					}
					break;

				default:
					break;
			}
		}
	};
	return (
		<div>
			{props.value.length > 0 && (
				<select
					value={value}
					onChange={onValueChange}
					style={{ height: "2rem" }}
				>
					{varInArr.map((el, index) => {
						return <option value={el}>{el}</option>;
					})}
				</select>
			)}
		</div>
	);
}

export default DropdownCellRenderer;
