import React from 'react'

export const PreformattedText = (props) => {
  return (
    <>
        <pre>
            {props.value}
        </pre>
    </>
  )
}
