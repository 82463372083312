import { Box, Typography } from "@mui/material";

const DataLabelContainerStyles = {
  m: 1,
  border: "1px solid",
  borderColor: "grey.300",
  display: "flex",
  flexWrap: "wrap",
  justifyContent: "center",
};

const DataLabelItemStyles = {
  borderRight: "1px solid",
  borderLeft: "1px solid",
  borderColor: "grey.300",
  minHeight: "2.5rem",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flex: 1,
  gap: "15px",
  py: 1,
  px: 2,
  my: 1,
};

const StrongTextStyles = {
  color: "#0055AF",
  fontWeight: 600,
  fontSize: "1.6rem",
  lineHeight: "2rem",
};

const TextStyles = {
  fontSize: "0.8rem",
  color: "#1F2D3D",
  fontFamily: "Arial",
};

const DataLabelItem = (props) => {
  const { data } = props;
  return (
    <>
      <Box sx={DataLabelItemStyles}>
        {data.image && <img alt="" src={data.image} style={{ maxWidth: "1.5rem" }} />}
        {data.strong && (
          <Typography sx={StrongTextStyles}>{data.strong}</Typography>
        )}
        {data.text && <Typography sx={TextStyles}>{data.text}</Typography>}
      </Box>
    </>
  );
};

const DataLabel = (props) => {
  const { data } = props;
  return (
    <>
      <Box sx={DataLabelContainerStyles}>
        {data?.details?.map((element, index) => (
          <DataLabelItem data={element} />
        ))}
      </Box>
    </>
  );
};

export default DataLabel;
