import React, { useState } from "react";
import CustomDateRangePicker from "../../../components/CustomDateRangePicker";
import moment from "moment";

const CustomDateRange = (props) => {
  const { data } = props;

  const state = [
    new Date(moment(data.defaultFromDate)),
    new Date(moment(data.defaultToDate)),
  ];

  const [dates, setDates] = useState(state);

  const handleDateRangeChange = (values, data) => {
    setDates(values);
  };

  return (
    <>
      <CustomDateRangePicker
        label={data.label}
        labeldirection={"column"}
        values={dates}
        isMandatory={false}
        width="4rem"
        onChange={(values) => handleDateRangeChange(values, data)}
      />
    </>
  );
};
export default CustomDateRange;
