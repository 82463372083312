import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import CustomDateRangePicker from "../../../components/CustomDateRangePicker";
import SelectRenderer from "../../../components/Select/SelectRenderer";
import ActionComponents from "./actionComponents";
import { Grid, InputAdornment, TextField } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import IconButton from "@mui/material/IconButton";

import moment from "moment";
import TextFieldWithLabel from "../../../components/TextField/TextFieldWithLabel";
import EditIcon from "@mui/icons-material/Edit";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import AddIcon from '@mui/icons-material/Add';
import AddCircleOutline from "@mui/icons-material/AddCircleOutline";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import LockIcon from "@mui/icons-material/Lock";
import CustomDatePicker from "../../../components/CustomDatePicker";
import TimePicker from "react-time-picker";
import { CustomModal } from "./customModal";
import InfoIcon from '@mui/icons-material/Info';
export const CustomRendererComponent = (props) => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { tableInfo, editActionInfo } = useSelector((state) => state.home);
	const { formMasterValuesData } = useSelector((state) => state.filter);
	const [updatedValue, setUpdatedValue] = useState({});
	const [open, setOpen] = useState(false);
	const updateState = (params) => {
		console.log(params);
	};
	const handleDateRangeChange = (values) => {
		// let params = {};
		// params[`${filterObj.label}`] = values;
		// this.updateState(params);
	};

	const handleClose = () => {
		setOpen(false);
	};

	const renderTableComponents = () => {
		let value = "";
		if (props.type === "valueFormatter") {
			props.keys.forEach((obj, index) => {
				if (index === props.keys.length - 1) {
					value = value + props.data[`${obj}`];
				} else {
					value = value + props.data[`${obj}`] + (props.separator || " ");
				}
			});
		}
		const handleTextFieldChange = (e) => {
			const { name, value } = e.target;
		};
		const handleInputChange = (e) => {
			const { label, value } = e.target;
			const updateObj = {
				currentRow: props.data,
				field: props.colDef.field,
				value: value,
				rowData: props.rowData,
				rowIndex: parseInt(props.data.id) - 1,
			};
			setUpdatedValue(updateObj);
		};
		const handleOnBlur = () => {
			// props.handleCellChange(updatedValue);
			const { field, value, currentRow, rowIndex } = updatedValue;
			if (Object.keys(updatedValue).length > 0) {
				let params = {};
				if (props.action && props.action.type) {
					switch (props.action.type) {
						case "ADD_TO_TABLE":
							const updatedData = tableInfo[`${props.parent_table_key}`].filter(
								(obj) => {
									if (obj.id === currentRow.id) {
										obj[`${props.colDef.field}`] = value;
										return obj;
									}
									return obj;
								},
							);
							params[`${props.parent_table_key}`] = [...updatedData];
							dispatch({
								type: "TABLE_DATA",
								payload: params,
							});

							break;
						case "VARIANCE_DIFF":
							const columns = props.colDef.field.split("_");
							let quarterName = columns[0];
							const diff =
								parseInt(value.replace(/\,/g, "")) -
								parseInt(
									currentRow[`${quarterName}_mfp_original_plan`].replace(
										/\,/g,
										"",
									),
								);
							const varianceDiff = tableInfo[
								`${props.parent_table_key}`
							].filter((obj) => {
								if (obj.id === currentRow.id) {
									obj[`${props.colDef.field}`] = value;
									obj[`${quarterName}_var`] = diff;
									return obj;
								}
								return obj;
							});
							params[`${props.parent_table_key}`] = [...varianceDiff];
							dispatch({
								type: "TABLE_DATA",
								payload: params,
							});
							break;
						case "MONTHLY_VARIANCE_DIFF":
							console.log(
								"Mnthly Vairance",
								tableInfo[`${props.parent_table_key}`],
							);
							const monthlyColumns = props.colDef.field.split("_");
							let monthlyQuarterName = monthlyColumns[0];
							const varianceMonthDiff =
								parseInt(value.replace(/\,/g, "")) -
								parseInt(
									currentRow[`${monthlyQuarterName}_mfp_original_plan`].replace(
										/\,/g,
										"",
									),
								);
							const varianceDiffData = tableInfo[
								`${props.parent_table_key}`
							].filter((obj) => {
								if (obj.id === currentRow.id) {
									obj[`${props.colDef.field}`] = value;
									obj[`${monthlyQuarterName}_var`] = varianceMonthDiff;
									return obj;
								}
								return obj;
							});
							params[`${props.parent_table_key}`] = [...varianceDiffData];
							dispatch({
								type: "TABLE_DATA",
								payload: params,
							});
							break;
						default:
							break;
					}
				}
			}
		};
		const handleLockOption = (rowData) => {
			let params = {};
			// const metricData = tableInfo["tb_plan_department_locked_celldata"]
			let metricData = tableInfo[`${props.action.parent_table_key}`];
			switch (props.action.type) {
				case "ROW_LOCKING":
					metricData = metricData.filter((obj) => {
						if (obj.ref === rowData.ref) {
							obj[`${props.colDef.field}_locked`] = obj[
								`${props.colDef.field}_locked`
							]
								? false
								: true;
							return obj;
						}
						return obj;
					});
					params[`${props.action.parent_table_key}`] = metricData;
					dispatch({
						type: "TABLE_DATA",
						payload: params,
					});
					break;

				default:
					break;
			}
		};

		const onAction = (action) => {
			if (action.actionName === "modal") {
				setOpen(!open);
			} else {
				return null;
			}
		};

		const displayIcons = (obj) => {
			switch (obj.type) {
				case "Edit":
					// return <EditIcon />
					return (
						<IconButton
							aria-label="close"
							onClick={() =>
								navigate(process.env.REACT_APP_BASE_ROUTE + obj.navigationPath)
							}
						>
							<EditIcon />
						</IconButton>
					);
					break;
				case "Copy":
					// return <ContentCopyIcon />
					return (
						<IconButton aria-label="close">
							<ContentCopyIcon />
						</IconButton>
					);
					break;
				case "Add":
					return (
						<IconButton aria-label="close">
							<AddIcon />
						</IconButton>
					);
				case "Info":
					return (
						<IconButton aria-label="close">
							<InfoIcon 
								sx={{ cursor: "pointer" }}
								onClick={() => {
									onAction(obj.action);
								}}
							/>
						</IconButton>
					);	
				default:
					break;
			}
		};

		const renderIcons = (obj) => {
			switch (obj) {
				case "Edit":
					// return <EditIcon />
					return (
						<IconButton
							aria-label="close"
							onClick={() =>
								navigate(process.env.REACT_APP_BASE_ROUTE + obj.navigationPath)
							}
						>
							<EditIcon />
						</IconButton>
					);
					break;
				case "Copy":
					// return <ContentCopyIcon />
					return (
						<IconButton aria-label="close">
							<ContentCopyIcon />
						</IconButton>
					);
					break;
				case "Open":
					return <OpenInNewIcon />;
					break;
				case "ADD":
					return <AddCircleOutline />;
				default:
					break;
			}
		};
		switch (props.type) {
			case "status-dates":
				return (
					<>
						{(editActionInfo[`${props.parent_table_key}`] && props.data) ||
						props.default_open ? (
							<>
								<Grid container spacing={1}>
									<Grid item xs={6}>
										<SelectRenderer
											options={props.options}
											selectedItems={props.options.filter(
												(obj) => obj.value === props.data[`status`],
											)}
											isMulti={false}
											updateSelected={updateState}
										/>
									</Grid>
									<Grid item xs={6}>
										<CustomDateRangePicker
											labeldirection={"column"}
											values={[
												new Date(moment(props.dates[0])),
												new Date(moment(props.dates[1])),
											]}
											onChange={handleDateRangeChange}
										/>
									</Grid>
								</Grid>
							</>
						) : (
							<>
								<p>
									{props.data ? (
										props.data[`${props.mappingKey}`]
									) : (
										<>{props.value ? props.value.value : null}</>
									)}
								</p>
							</>
						)}
					</>
				);
				break;
			case "select":
				const optionsList = formMasterValuesData.filter((obj) => {
					if (parseInt(obj.parent_id) === props.filter_id) {
						obj.label = obj.name;
						obj.value = obj.name;
						return obj;
					}
				});
				return (
					<>
						{(editActionInfo[`${props.parent_table_key}`] && props.data) ||
						props.default_open ? (
							<>
								<Grid container spacing={1}>
									<Grid item xs={12}>
										<SelectRenderer
											options={optionsList}
											selectedItems={optionsList.filter(
												(obj) =>
													obj.value === props.data[`${props.mappingKey}`],
											)}
											isMulti={false}
											updateSelected={updateState}
										/>
									</Grid>
								</Grid>
							</>
						) : (
							<>
								<p>{props.data && props.data[`${props.mappingKey}`]}</p>
							</>
						)}
					</>
				);
				break;
			case "textfield":
				return (
					<Grid container spacing={1}>
						<Grid item xs={12}>
							{props.data &&
							((editActionInfo[`${props.parent_table_key}`] &&
								props.data &&
								props.value) ||
								props.default_open) ? (
								<>
									<div className="spacer"></div>
									<TextFieldWithLabel
										size="small"
										id="filled-hidden-label-normal"
										variant="outlined"
										defaultValue={props.value}
										type={"text"}
										className="ag-theme-custom-react"
										onChange={handleInputChange}
										handleOnBlur={handleOnBlur}
										startAdornment={
											props.value &&
											props.data.position === "start" && (
												<InputAdornment position="start">
													{props.data.symbol}
												</InputAdornment>
											)
										}
										endAdornment={
											props.value && props.data.position === "end" ? (
												<>
													<InputAdornment position="end">
														{props.data.symbol}
														{props.isLockRequired ? (
															<>
																<LockIcon
																	sx={{ fontSize: 15, cursor: "pointer" }}
																	onClick={() => {
																		handleLockOption(props.data);
																	}}
																/>
															</>
														) : null}
													</InputAdornment>
												</>
											) : (
												<>
													{props.isLockRequired ? (
														<>
															<LockIcon
																sx={{ fontSize: 15, cursor: "pointer" }}
																onClick={() => {
																	handleLockOption(props.data);
																}}
															/>
														</>
													) : null}
												</>
											)
										}
									/>
								</>
							) : (
								<>
									<p>
										{props.data ? (
											<>
												{props.data.symbol ? (
													<>
														{props.data.position === "start" &&
														props.data[`${props.mappingKey}`] ? (
															<>
																{props.data.symbol}{" "}
																{props.data[`${props.mappingKey}`]}
															</>
														) : null}
														{props.data.position === "end" &&
														props.data[`${props.mappingKey}`] ? (
															<>
																{props.data[`${props.mappingKey}`]}{" "}
																{props.data.symbol}
															</>
														) : null}
														<></>
													</>
												) : (
													<>{props.data[`${props.mappingKey}`]}</>
												)}
											</>
										) : null}
									</p>
								</>
							)}
						</Grid>
					</Grid>
				);
				break;
			case "valueFormatter":
				return (
					<>
						{props.data && props.data[`${props.mappingKey}`] ? (
							<ActionComponents
								data={{
									components: props.components,
									otherInfo: {
										isLabelAction: props.data[`${props.mappingKey}`],
										actionType: props.actionType,
									},
								}}
							/>
						) : (
							value
						)}
					</>
				);
				break;
			case "textfield-with-lock":
				if (props.data) {
				}
				return (
					<>
						{props.data &&
						props.metricKey &&
						props.metricValues.includes(props.data[`${props.metricKey}`]) &&
						props.conditionValues.indexOf(
							props.data[`${props.conditionKey}`],
						) === -1 ? (
							<>
								<Grid container spacing={1}>
									<Grid item xs={12}>
										<TextField
											size="small"
											id="filled-hidden-label-normal"
											variant="outlined"
											value={props.data[`${props.mappingKey}`]}
											type={"text"}
											onChange={handleTextFieldChange}
										/>
									</Grid>
								</Grid>
							</>
						) : (
							<>
								<p>{props.data && props.data[`${props.mappingKey}`]}</p>
							</>
						)}
					</>
				);
				break;
			case "actions-list":
				return (
					<span style={{ paddingTop: "22px" }}>
						<>{renderIcons(props.icon)} &nbsp;&nbsp;</>
					</span>
				);
				break;
			case "icon-actions":
				return (
					<>
						<span style={{ ...props.style }}>
							{props.options.map((obj) => (
								<>{displayIcons(obj)} &nbsp;&nbsp;</>
							))}
						</span>
					</>
				);
				break;
			case "actionable-icons":
				return (
					<>
						<span style={{ ...props.style }}>
							{props.options.map((obj) => (
								<>{displayIcons(obj)} &nbsp;&nbsp;</>
							))}
						</span>
					</>
				);
			case "bold":
				return (
					<>
						{props.data && props.data.text_type === "bold" ? (
							<>
								<b>{props.data[`${props.mappingKey}`]}</b>
							</>
						) : (
							<>
								<p>{props.data && props.data[`${props.mappingKey}`]}</p>
							</>
						)}
					</>
				);
				break;
			case "date":
				return (
					<>
						<Grid container spacing={1}>
							<Grid item xs={12}>
								<CustomDatePicker labeldirection={"column"} />
							</Grid>
						</Grid>
					</>
				);
			case "time":
				return (
					<>
						<Grid container spacing={1}>
							<Grid item xs={12}>
								<TimePicker />
							</Grid>
						</Grid>
					</>
				);
			case "aggregate-link":
				return (
					<>
						{props.isAggregateLevel && typeof props.value === "object" ? (
							<>
								<ActionComponents
									data={{
										components: props.components,
										otherInfo: {
											isLabelAction: props.value.value,
											actionType: props.actionType,
											data: props.data,
											uniqueKey: props.node.key,
										},
									}}
								/>
							</>
						) : (
							<>
								<p>{props.data && props.data[`${props.mappingKey}`]}</p>
							</>
						)}
					</>
				);
			case "link":
					return (
						<>
							{props.value ? (
								<>
									<a href={props.value} target={(props.target || "_self" )} >{props.value}</a> 
								</>
							): null }
						</>
					);
			default:
				return (
					<>
						{props.data && props.data[`${props.mappingKey}`] ? (
							<ActionComponents
								data={{
									components: props.components,
									otherInfo: {
										isLabelAction: props.data[`${props.mappingKey}`],
										actionType: props.actionType,
										data: props.data,
									},
								}}
							/>
						) : null}
					</>
				);
				break;
		}
	};
	return (
		<>
			{open ? (
				<>
					<CustomModal
						show={open}
						{...props.options[props.index]["action"]}  
						handleClose={handleClose}
					/>
				</>
			) : null}
			{/* {show ? <SkuGraphModel show={show} handleClose={handleClose} data={selectedSku} /> : null } */}
			{renderTableComponents()}
		</>
	);
};
