import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import ComponentResolver from "../Resolver/ComponentResolver";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import SnackBar from "../../../components/SnackBar";
import { addNotifications } from "../data";
import CustomActionButton from "./CustomActionButton";
import { deleteData } from "../action";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

export const CustomModal = (props) => {
  const { show, type, title, components } = props;

  const [showMessage, setShowMessage] = useState(false);
  const {
    selectedRowInfo,
    tableInfo,
    formEditedValues,
    dependentInfo,
    showActiveTab,
    newDynamicTabIndexValue,
  } = useSelector((state) => state.home);
  const { formMasterValuesData } = useSelector((state) => state.filter);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [open, setOpen] = useState(props.show);

  useEffect(() => {
    setOpen(props.show);
  }, [props.show]);

  const updateActionOnModalClose = (item) => {
    switch (item.type) {
      case "UPDATE_PARENT_TABLE":
        if (tableInfo[`${item.parent_table_key}`]) {
          let uniqueKey = null;

          if (selectedRowInfo[`${item.parent_table_key}`]) {
            uniqueKey = selectedRowInfo[`${item.parent_table_key}`].map(
              (obj) => obj[`${item.unique_key}`]
            )[0];
          }
          const updatedData = tableInfo[`${item.parent_table_key}`].filter(
            (obj) => {
              if (obj[`${item.unique_key}`] == uniqueKey) {
                item.updating_keys.forEach((updateObj) => {
                  obj[`${updateObj}`] = 1;
                });
                return obj;
              }
              return obj;
            }
          );
          let params = {};
          params[`${item.parent_table_key}`] = [...updatedData];
          dispatch({
            type: "TABLE_DATA",
            payload: params,
          });
        }
        break;
      case "UPDATE_EDITED_VALUES_IN_PARENT_TABLE":
        if (tableInfo[`${item.parent_table_key}`]) {
          let selectedRowIds = [];

          if (selectedRowInfo[`${item.parent_table_key}`]) {
            selectedRowIds = selectedRowInfo[`${item.parent_table_key}`].map(
              (obj) => obj[`${item.unique_key}`]
            );
          }
          const updatedData = tableInfo[`${item.parent_table_key}`].filter(
            (obj) => {
              if (selectedRowIds.includes(obj[`${item.unique_key}`])) {
                item.updating_keys.forEach((updateObj) => {
                  if (item.isMappingKeys) {
                    if (
                      formEditedValues[
                        `${item.updateMappingKeys[`${updateObj}`]}`
                      ]
                    ) {
                      obj[`${updateObj}`] =
                        formEditedValues[
                          `${item.updateMappingKeys[`${updateObj}`]}`
                        ];
                    }
                  } else {
                    obj[`${updateObj}`] = formEditedValues[`${updateObj}`];
                  }
                });
                return obj;
              }
              return obj;
            }
          );
          let params = {};
          params[`${item.parent_table_key}`] = [...updatedData];
          dispatch({
            type: "TABLE_DATA",
            payload: params,
          });
        }
        break;

      default:
        break;
    }
  };
  const handleClose = async(item, index) => {
    console.log("Handle close", item);
    if (item) {
      if (item && item.type) {
        updateActionOnModalClose(item);
      }
      if (item && item.actionName === "displayMessage") {
        setShowMessage(true);
        if (item.otherActionName === "notifications") {
          addNotifications(item);
        }
        if (
          item["sortBasedOnDropdown"] &&
          item["sortBasedOnDropdown"] === "SORT_BASED_ON_DROPDOWN"
        ) {
          let tableParams = {};
          let tableData = [];
          if (dependentInfo[`${item.key1}`]) {
            tableData = tableInfo[`${item.parent_table_key}_${item.key1}`];
          }
          if (dependentInfo[`${item.key2}`]) {
            tableData = tableInfo[`${item.parent_table_key}_${item.key2}`];
          }
          tableParams[`${item.parent_table_key}`] = tableData;
          dispatch({
            type: "TABLE_DATA",
            payload: tableParams,
          });
        }
        if (item["navigationPath"]) {
          navigate(process.env.REACT_APP_BASE_ROUTE + item.navigationPath);
        }
      }
      if (item && item.actionName === "navigate") {
        if (item.dependentOn) {
          let params = {};
          params[`${item.dependentOn}`] = true;
          dispatch({
            type: "DEPENDENT_COMPONENTS",
            payload: params,
          });
        }
        navigate(process.env.REACT_APP_BASE_ROUTE + item.path);
      }
      if (item.actionName === "tab-redirect") {
        if (item.path) {
          navigate(process.env.REACT_APP_BASE_ROUTE + item.path);
        }
        dispatch({
          type: "TAB_REDIRECT",
          payload: {
            activeTabIndex: item.activeTabIndex,
            parentTabIndex: item.parentTabIndex,
            activeTabValue: item.activeTabValue,
            parentTabValue: item.parentTabValue,
          },
        });
      }
      if (item.type === "ADD_BRANDS_TO_DROPDOWN") {
        if (tableInfo[`${item.parent_table_key}`]) {
          console.log("All Values", tableInfo[`${item.parent_table_key}`]);
          const formValues = [];
          tableInfo[`${item.parent_table_key}`].filter((obj, index) => {
            let brandObj = {
              id: index,
              name: obj.placeholder,
              parent_type_id: item.filter_id,
              parent_id: item.filter_id,
              "Screen Name": null,
              order_value: null,
              infotip: null,
              infotip_enabled: null,
              is_default_value: null,
              is_active: null,
              isNewBrand: true,
              department: obj.department,
            };
            formValues.push(brandObj);
          });

          dispatch({
            type: "ADD_FORM_MASTER_VALUES",
            payload: [...formValues],
          });
          let placeholderParams = {};
          placeholderParams[`${item.parent_table_key}`] = [];
          console.log("Table I nfo Data", placeholderParams);
          dispatch({
            type: "TABLE_DATA",
            payload: placeholderParams,
          });
        }
      } else if (item.type === "RESET_BRANDS_TO_DROPDOWN") {
        const removeNewBrands = formMasterValuesData.filter(
          (obj) => !obj.isNewBrand
        );

        setOpen(false);
      } else if (item.type === "UPDATE_MASTER_PLAN") {
        let reqParams = {};
        reqParams[`${item.filter_id}`] = false;
        dispatch({
          type: "DEPENDENT_COMPONENTS",
          payload: reqParams,
        });
        let placeholderParams = {};
        placeholderParams[`${item.source}`] = tableInfo[`${item.key}`];
        dispatch({
          type: "TABLE_DATA",
          payload: placeholderParams,
        });
        setOpen(false);
      } else if (item.actionName === "click") {
        let params = {};
        params[`${item.key}`] = dependentInfo[`${item.key}`]
          ? !dependentInfo[`${item.key}`]
          : true;
        if (item.otherKey && dependentInfo[`${item.otherKey}`]) {
          params[`${item.otherKey}`] = dependentInfo[`${item.otherKey}`]
            ? !dependentInfo[`${item.otherKey}`]
            : true;
        } else {
          params[`${item.otherKey}`] = true;
        }
        dispatch({
          type: "DEPENDENT_COMPONENTS",
          payload: params,
        });
      }else if(item.actionName === "REQUEST_API_CALL"){
        if(selectedRowInfo[`${item.mappingKey}`] && selectedRowInfo[`${item.mappingKey}`].length > 0){
            await deleteData(item.apiUlr,{model_names: selectedRowInfo[`${item.mappingKey}`].map(obj=> obj.name)}).then(res=>{
              console.log("Delete apis list",res)
              if(typeof res === 'object'){
                setShowMessage(true)
              }
              
            }).catch(err=>{
              console.log(err)
            })
        }
      }
    }

    setOpen(false);
    // props.handleClose();
  };
  const handleMessageClose = () => {
    setShowMessage(false);
    if(props.footer_actions[0].info.details[1].action.navigationPath){
      navigate(
        process.env.REACT_APP_BASE_ROUTE +
          props.footer_actions[0].info.details[1].action.navigationPath
      );
    }
    
  };
  return (
    <>
      {showMessage && (
        <>
          <SnackBar
            message={props.footer_actions[0].info.details[1].action.message}
            variant="success"
            handleClose={handleMessageClose}
          />
        </>
      )}
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        fullWidth={props.fullWidth}
        maxWidth={props.width || "lg"}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
        >
          {title}
        </BootstrapDialogTitle>
        <DialogContent dividers style={{ height: props.height }}>
          {props.statement ? (
            <p>{props.statement}</p>
          ) : (
            <ComponentResolver pageObject={{ components: props.components }} />
          )}
        </DialogContent>
        <DialogActions>
          {props.footer_actions.map((obj) => (
            <div style={{ textAlign: obj.info.alignment }}>
              {obj.info.details?.map((item, index) => (
                <Button
                  style={{ textTransform: "none", margin: 5 }}
                  variant={item.varient}
                  size={item.size}
                  onClick={() => {
                    handleClose(item.action, index);
                    if (item?.action?.actionName === "showActiveTab") {
                      if (showActiveTab !== true) {
                        dispatch({
                          type: "SHOW_ACTIVE_TAB",
                          payload: item.action,
                        });
                      } else {
                        dispatch({
                          type: "NEWLY_ADDED_TAB_INDEX",
                          payload: newDynamicTabIndexValue + 1,
                        });
                        dispatch({
                          type: "ADD_DYNAMIC_SCENARIO",
                          payload: [
                            item?.action?.dynamicTabComponent,
                            newDynamicTabIndexValue,
                          ],
                        });
                      }
                    }
                  }}
                >
                  {item.name}
                </Button>
              ))}
            </div>
          ))}

          {/* <ComponentResolver pageObject={{components: props.footer_actions}} /> */}
        </DialogActions>
      </BootstrapDialog>
    </>
  );
};
