import * as React from "react";
import { TextareaAutosize } from "@material-ui/core";

export default function TextArea(props) {
	return (
		<div >
			<TextareaAutosize variant="outlined" multiline minRows={props.data.rows} style={{ width: "100%" }}/>
		</div>
	);
}
