import React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import Typography from '@mui/material/Typography';

export default function ListComponent({ data }) {
  return (
    <List
      dense
      sx={{ width: '100%', maxWidth: 460, bgcolor: 'background.paper' }}
    >
      {data?.map((item) => (
        <ListItem
          key={item}
          secondaryAction={
            <Typography
              sx={{ fontSize: item.style === 'bold' ? 30 : 14, color: '#3089e4' }}
              color='text.secondary'
              gutterBottom
            >
              {item.count}
              {item.calc && <span>({item.calc_val}<>%</>)</span>}
            </Typography>
          }
          disablePadding
        >
          <ListItemButton>
            <Typography sx={{ fontSize: item.style === 'bold' ? 30 : 14 }} gutterBottom>
              {item.name}
            </Typography>
          </ListItemButton>
        </ListItem>
      ))}
    </List>
  );
}
