const initialState = {
	filterMenuData: [],
    filterMasterValuesData: [],
    formMenuData:[],
    formMasterValuesData:[]
};

const filterReducer = (state = initialState, action) => {
	switch (action.type) {
		case "UPDATE_FILTER_DATA":
            const { tb_filter_master_copy, tb_filter_master_values } = action.payload;
			return {
				...state,
				filterMenuData: tb_filter_master_copy,
                filterMasterValuesData: tb_filter_master_values
			};

		case "UPDATE_FORM_DATA":
			const { tb_form_master, tb_form_master_values } = action.payload;
			return {
				...state,
				formMenuData: tb_form_master,
                formMasterValuesData: tb_form_master_values
			};
		case "ADD_FORM_MASTER_VALUES":
			return {
				...state,
				formMasterValuesData: [...state.formMasterValuesData, ...action.payload ],
			  };
		case "ADD_NEW_FORM_MASTER_VALUES":
			return {
				...state,
				formMasterValuesData: [...action.payload ],
			  };
		default:
			return state;
	}
};

export default filterReducer;
