import React from "react";
import { Grid } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { Table } from "../../../components/Table";
import Typography from "@mui/material/Typography";
import CustomActionButton from "./CustomActionButton";
import _ from "lodash";
const HorizontalTables = (props) => {
  const { tables } = props;
  const {
    selectedRowInfo,
    tableInfo,
    editActionInfo,
    dependentInfo,
    formEditedValues,
    columnsInfo,
  } = useSelector((state) => state.home);
  const dispatch = useDispatch();
  const gridLength = 12 / tables.length;

  const autoGroupColumnDef = (obj) => {
    return {
      headerValueGetter: (params) => `${obj.groupColumnName}`,
      minWidth: 220,
      cellRendererParams: {
        suppressCount: true,
        checkbox: true,
      },
    };
  };
  const onSelectionChanged = (rows, gridApiObj, tableObj) => {
    if (tableObj["action"]) {
      const selectedRows = rows.map((obj) => obj.id);
      switch (tableObj.action.type) {
        case "SELF_TABLE_DATA_CHANGE":
          if (tableInfo[`${tableObj.action.source}`]) {
            const updatedData = tableInfo[`${tableObj.action.source}`];
            const origionalData = Object.assign([],[...tableInfo[`${tableObj.action.destination}`]]);
            let tableUpdatedData = []
            if(selectedRows.length == tableObj.action.isDefaultCheckBoxSelection.length ){
              tableUpdatedData = Object.assign([],[...tableInfo[`${tableObj.action.tableDummyData}`]]);;
            }else{
               tableUpdatedData = Object.assign([],[...tableInfo[`${tableObj.action.destination}`]]).filter((obj, index) => {
                if (selectedRows.indexOf(obj.id) != -1) {
                  let params = Object.assign({},updatedData[index])
                  Object.keys(params).forEach((copyObj) => {
                    obj[`${copyObj}`] = {...updatedData[index]}[`${copyObj}`];
                  });
                  // console.log("Update Index",)
                    // obj = Object.assign({},updatedData[index])
                  // obj['forecast_reg_weeks'] = 2999
                  return obj;
                } else {
  
                  return obj;
                }
              });
            }

          console.log("Updated Oriasdf",selectedRows,tableUpdatedData)
            
            let payload = {};
            payload[`${tableObj.action.parent_table_key}`] = _.clone([
              ...tableUpdatedData,
            ]);
            if (tableObj.action.otherAction) {
              switch (tableObj.action.otherAction.type) {
                case "UPDATE_CHILD_TABLE_DATA":
                  if (tableInfo[`${tableObj.action.otherAction.source}`]) {
                    const updatedData =
                      tableInfo[`${tableObj.action.otherAction.source}`];
                    const origionalData = [
                      ...tableInfo[
                        `${tableObj.action.otherAction.destination}`
                      ],
                    ];
                    if (selectedRows.length === 2) {
                      payload[
                        `${tableObj.action.otherAction.parent_table_key}`
                      ] = [...origionalData];
                    } else {
                      payload[
                        `${tableObj.action.otherAction.parent_table_key}`
                      ] = [...updatedData];
                    }
                  }
                  break;

                default:
                  break;
              }
            }
            dispatch({
              type: "TABLE_DATA",
              payload: payload,
            });
          }

          break;

        default:
          break;
      }
    }
  };
  return (
    <Grid container spacing={2}>
      {tables.map((obj) => (
        <>
          <Grid
            item
            xs={12}
            sm={
              props.isTableToggleRequired
                ? dependentInfo[`${props.dependentKey}`] === true &&
                  dependentInfo[`${obj[`dependentOn`]}`]
                  ? 12 / tables.length
                  : 12
                : gridLength
            }
            md={
              props.isTableToggleRequired
                ? dependentInfo[`${props.dependentKey}`] === true &&
                  dependentInfo[`${obj[`dependentOn`]}`]
                  ? 12 / tables.length
                  : 12
                : gridLength
            }
            lg={
              props.isTableToggleRequired
                ? dependentInfo[`${props.dependentKey}`] === true &&
                  dependentInfo[`${obj[`dependentOn`]}`]
                  ? 12 / tables.length
                  : 12
                : gridLength
            }
            xl={
              props.isTableToggleRequired
                ? dependentInfo[`${props.dependentKey}`] === true &&
                  dependentInfo[`${obj[`dependentOn`]}`]
                  ? 12 / tables.length
                  : 12
                : gridLength
            }
          >
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Typography variant="h6subtitle2">
                <h4>{obj.title}</h4>
              </Typography>

              {obj.actionButton && (
                <>
                  <CustomActionButton data={obj.actionButton} />
                </>
              )}
            </div>
            {obj[`dependentOn`] ? (
              <>
                {dependentInfo[`${obj[`dependentOn`]}`] ? (
                  <>
                    <Table
                      apikey={obj.key}
                      columnDefs={obj.column}
                      floatingFilter={false}
                      // height={"450px"}
                      rowSelection={"multiple"}
                      autoGroupColumnDef={autoGroupColumnDef(obj)}
                      {...obj}
                      tableCssStyle={
                        obj.tableCssStyle === undefined
                          ? "default"
                          : obj.tableCssStyle
                      }
                      onRowselectionChanged={(rows, gridObj) => {
                        onSelectionChanged(rows, gridObj, obj);
                      }}
                    />
                  </>
                ) : null}
              </>
            ) : (
              <>
                <Table
                  apikey={obj.key}
                  columnDefs={obj.column}
                  floatingFilter={false}
                  // height={"450px"}
                  rowSelection={"multiple"}
                  autoGroupColumnDef={autoGroupColumnDef(obj)}
                  {...obj}
                  tableCssStyle={
                    obj.tableCssStyle === undefined
                      ? "default"
                      : obj.tableCssStyle
                  }
                  onRowselectionChanged={(rows, gridObj) => {
                    onSelectionChanged(rows, gridObj, obj);
                  }}
                />
              </>
            )}

            {obj.counter && (
              <div>
                {obj.counter.label}
                <div>
                  Min
                  <input
                    defaultValue={2}
                    type="number"
                    style={{
                      width: "90px",
                      height: "30px",
                      margin: "0px 10px",
                      marginTop: "7px",
                    }}
                  />
                  Max
                  <input
                    defaultValue={6}
                    type="number"
                    style={{
                      width: "90px",
                      height: "30px",
                      margin: "0px 10px",
                      marginTop: "7px",
                    }}
                  />
                </div>
              </div>
            )}
          </Grid>
        </>
      ))}
    </Grid>
  );
};
export default HorizontalTables;
