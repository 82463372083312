import React from "react";
import { experimentalStyled as styled } from "@mui/material/styles";
import { Paper } from "@mui/material";

import DropdownTreeSelect from "react-dropdown-tree-select";
import "react-dropdown-tree-select/dist/styles.css";
// import "./styles.css";

const Item = styled(Paper)(({ theme, width }) => ({
  ...theme.typography.body2,
  textAlign: "left",
  boxShadow: "none",
  background: "transparent",
  width,
  color: theme.palette.text.secondary,
}));

const DropdownTreeSelectRenderer = (props) => {
  const {
    type,
    filterLabel,
    isMandatory,
    isMulti,
    placeholder,
    updateSelected,
    data,
    selectedItems,
    width,
    style
  } = props;
  console.log(props);
  const onChange = (currentNode, selectedNodes) => {
    console.log("onChange::", currentNode, selectedNodes);
  };
  const onAction = (node, action) => {
    console.log("onAction::", action, node);
  };
  const onNodeToggle = (currentNode) => {
    console.log("onNodeToggle::", currentNode);
  };

  return (
    // <div style={{ width: "100%" }}>
      <Item width={width} style={style} >
        {/* <div style={{ fontSize: "14px", color: "#758490" }}> */}
          <label>
            {filterLabel}
            {isMandatory ? <span style={{ color: "red" }}> * </span> : null}
          </label>
        {/* </div> */}
        {/* <div style={{ height: "6px" }}></div> */}
          <DropdownTreeSelect
            texts={{ placeholder: "Search/Choose" }}
            data={data}
            onChange={onChange}
            onAction={onAction}
            onNodeToggle={onNodeToggle}
            inlineSearchPlaceholder={placeholder}
          />
      </Item>
    // </div>
  );
};

export default DropdownTreeSelectRenderer;
