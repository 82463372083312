import React from "react";

const CellImageField = (props) => {
	return (
		<div style={props.style}>
			<img
				width={
					props.imageWidth || props.data[`${props.colDef.field}_image_text`]
						? "60%"
						: "80%"
				}
				src={require(`../../../assets/images/assort/${props.value}.png`)}
				alt=""
			/>
			{props.data[`${props.colDef.field}_image_text`] && (
				<div>{props.data[`${props.colDef.field}_image_text`]}</div>
			)}
		</div>
	);
};

export default CellImageField;
