import React from "react";
import { useDispatch, useSelector } from "react-redux";
import TextFieldWithLabel from "../../../components/TextField/TextFieldWithLabel";

const CellTextField = (props) => {
  const { tableValues } = useSelector((state) => state.home);
  const dispatch = useDispatch();
  const CellValueHandler = (newValue) => {
    let payload = {};
    let tableData = tableValues[props.mappingKey];
    tableData[props.rowIndex][props.column.colId] = newValue;
    payload[props.mappingKey] = tableData;
    dispatch({
      type: "TABLE_VALUES",
      payload: payload,
    });
    console.log("new value", newValue);
    console.log("values", tableValues);
  };
  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <TextFieldWithLabel
        type="text"
        defaultValue={props.value}
        disabled={props.disabled}
        onChange={(e) => CellValueHandler(e.target.value)}
      />
    </div>
  );
};

export default CellTextField;
