import React, { useState,useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import CustomSelectDropdown from "../CustomSelectDropdown";

function DropdownCellRenderer(props) {
    const [options, setOptions] = useState([])
    const [isMulti, setIsMulti] = useState(false)
    const [selectedValues, setSelectedValues] = useState([])
	const dispatch = useDispatch();
	const { tableInfo } = useSelector((state) => state.home);
    const { formMasterValuesData ,formMenuData } = useSelector((state) => state.filter);
    
    useEffect(() => {
      console.log("=======",props,props.value)
        const optionsList = formMasterValuesData.filter((obj) => {
            if (parseInt(obj.parent_id) === props.filter_id) {
              obj.label = obj.name;
              obj.value = obj.name;
              return obj;
            }
          });
          const formDetails = formMenuData.filter(
            (obj) => parseInt(obj["categoryid"]) === props.filter_id
          )[0] || {};
          setIsMulti(formDetails.type === "multi-select")
          setOptions(optionsList)
          setSelectedValues(optionsList.filter(obj=> props.value.split(",").indexOf(obj.value) !==-1 ))
    }, [])
    
	const onValueChange = (updatedValues) => {
		setSelectedValues(updatedValues)
	};
	return (
        
		<div style={{width: (props.width || '200px' )}}>
			{props.value && (
				<CustomSelectDropdown
                    selectedValues={selectedValues}
                    options={options}
					onChange={onValueChange}
                    isMulti={isMulti}

				/>
			)}
		</div>
	);
}

export default DropdownCellRenderer;
