import { combineReducers } from "redux";
// import { connectRouter } from "connected-react-router";

import { homeReducer,filterReducer } from "../../pages/reducer";
export const rootReducer = (history) =>
	combineReducers({
		// router: connectRouter(history),
		router: history,
		home: homeReducer,
		filter:filterReducer
	});
