let result = [];
export const currencyFormatter = (params) => {
  return params.value ? "$" + formatNumber(params.value) : null;
};
export const percentageFormatter = (params) => {
  return params.value ? formatNumber(params.value) + "%" : null;
};
export const NoFormatter = (params) => {
  return params.value ? formatNumber(params.value) : null;
};
export const formatNumber = (number) => {
  return Math.floor(number)
    .toString()
    .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
};

export const formatData = (type, params) => {
  switch (type) {
    case "currencyFormatter":
      return currencyFormatter(params);
    case "percentageFormatter":
      return currencyFormatter(params);

      break;

    default:
      if (params.value) {
        if (params.data.symbol) {
          if (params.data.position === "start") {
            return `${params.data.symbol} ${params.value}`;
          }
          if (params.data.position === "end") {
            return `${params.value} ${params.data.symbol} `;
          }
        } else {
          return params.value;
        }
      } else {
        return null;
      }
      break;
  }
};

export const getColumnDefs = (columnData, isValueFormatterRequired) => {
  columnData.filter((obj) => {
    // value formatter
    if (obj["valueFormatterType"] || isValueFormatterRequired) {
      obj.valueFormatter = function (params) {
        return formatData(obj.valueFormatterType, params);
      };
    }

    // obj.cellStyle = function (params) {
    //     console.log("PARAMS I",params,params.data,params.data && params.data.hasOwnProperty("is_colour"),(params.data && params.data.metric_level  === "OTB Cost $"))
    //     // OTB Cost $
    //    if (params.data && params.data.hasOwnProperty("is_color") && ( params.data.metric_level === "OTB Cost $" )) {
    //     return {backgroundColor: '#f07c7c'};
    //    }

    //   return null;
    // }

    if (!obj["cellStyle"]) {
      obj.cellStyle = function (params) {
        if (params.context && params.context.editedColumns) {
          const data = params.context.editedColumns.filter((obj) => {
            if (
              obj.rowIndex == params.rowIndex &&
              obj.colId == params.column.colId
            ) {
              return obj;
            }
          });
          if (data.length > 0) {
            return {
              background: "#f6c6c8",
            };
          }
        }

        // review in season
        let regExp = /[a-zA-Z]/g;
        if (
          params.data &&
          params.data.hasOwnProperty("is_color") &&
          (params.data.metric_level === "OTB Cost $" ||
            params.data.metric_level === "OTB U") &&
          !regExp.test(params.value)
        ) {
          return { color: params.value.includes("-") ? "#F46F6F" : "#47CE57" };
        }
      };
    }

    if (obj["children"]) {
      obj["children"].filter((nestedObj) => {
        if (nestedObj["valueFormatterType"] || isValueFormatterRequired) {
          nestedObj.valueFormatter = function (params) {
            return formatData(nestedObj.valueFormatterType, params);
          };
        }
        if (!nestedObj["cellStyle"]) {
          nestedObj.cellStyle = function (params) {
            let regExp = /[a-zA-Z]/g;
            if (
              params.data &&
              params.data.hasOwnProperty("is_color") &&
              (params.data.metric_level === "OTB Cost $" ||
                params.data.metric_level === "OTB U") &&
              !regExp.test(params.value)
            ) {
              return {
                color: params.value.includes("-") ? "#F46F6F" : "#47CE57",
              };
            }
            if (params.context && params.context.editedColumns) {
              const nestedData = params.context.editedColumns.filter((obj) => {
                console.log(
                  obj.rowIndex,
                  params.rowIndex,
                  obj.colId,
                  params.column.colId
                );
                if (
                  obj.rowIndex == params.rowIndex &&
                  obj.colId == params.column.colId
                ) {
                  return obj;
                }
              });
              if (nestedData.length > 0) {
                return {
                  background: "#f6c6c8",
                };
              }
            }
          };
        }
      });
    }
    return obj;
  });

  return columnData;
};

export const getHeaderNames = (data, callingFrom) => {
  if (!callingFrom) {
    result = [];
  }
  data.filter((obj, index) => {
    if (obj.hasOwnProperty("children")) {
      getHeaderNames(obj["children"], "Nested");
    } else {
      result.push(obj.field);
    }
  });
};
