import React from "react";
import { useDispatch, useSelector } from "react-redux";
import AddIcon from "@mui/icons-material/Add";
const CellAddIcon = (props) => {
    const dispatch = useDispatch();
    const {
        selectedRowInfo,
        tableInfo,
        editActionInfo,
        dependentInfo,
        formEditedValues,
      } = useSelector((state) => state.home);
  const onAction = ()=>{
      console.log("OnAction Click",props)
      switch (props.action.type) {
          case "ADD_NEW_ROW":
            let params = {};
            const newRow = [];
            const tableData = tableInfo[`${props.action.parent_table_key}`];
            if (tableData && tableData.length > 0) {
              let rowObj = {};
              Object.keys(tableData[tableData.length-1]).forEach((obj) => {
                rowObj[`${obj}`] = props.action.defaultValue[`${obj}`];
                rowObj[`id`] = tableData.length + 1;
              });
              newRow.push(rowObj);
            } else {
              newRow.push(props.action.defaultValue);
            }
            tableData.splice((props.rowIndex + 1),0,newRow[0])
            // params[`${props.action.parent_table_key}`] = [...tableData, ...newRow];
            params[`${props.action.parent_table_key}`] = [...tableData];
            console.log("parmas list ",params)
            dispatch({
              type: "TABLE_DATA",
              payload: params,
            });
              
              break;
      
          default:
              break;
      }
  }

  const getIcon = ()=>{
    switch (props.iconType) {
        case "Add":
            return(
                <AddIcon
                            sx={{
                              color: "#1976D1",
                              height: "20px",
                              width: "20px",
                              verticalAlign: "middle",
                              margin: "0px 10px",
                              cursor:'pointer'
                            }}
                            onClick={onAction}
                          />
            )
            break;
    
        default:
            break;
    }
}
  return (
    <div >
      {props.value ? (props.position === 'start' ? (
          <>
            {getIcon()} {props.value}
          </>
           ) : (
            <>
                {props.value} {getIcon()}
            </>
          ) ) : null  }
    </div>
  );
};

export default CellAddIcon;
