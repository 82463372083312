import React, { useState } from "react";
import { Typography } from "@mui/material";
import TextFieldWithLabel from "../TextField/TextFieldWithLabel";
import { InputAdornment } from '@mui/material';

const CustomPromoKpiCard = (props) => {
  const { data } = props;
  const [counters, setCounters] = useState(
    [props.data.details[0]?.defaultValue, 0, null, null, null, null]
  );

  const updateValues = (val, index) => {
  let res = 0;
    if (index === 2) {
      res = val * props.data.details[0].defaultValue;
    const nextCounters = counters.map((c, i) => {
      if (i === 4) {
        return res;
      } else {
        return c;
      }
    });
    setCounters(nextCounters);
  }
  }
  return (
    <div style={{ padding: 10 }}>
      <Typography style={data.headerStyle}>{data.header}</Typography>
      <hr />
      {data.component === "textField" && (
        <div style={data.style}>
          <TextFieldWithLabel
            type={data.type}
            label={data.label}
            width={data.width}
            defaultValue={data.defaultValue}
          />
        </div>
      )}
      {data.component === "seriesOfTextfield" && (
        <div style={{ display: "flex" }}>
          {data.details.map((obj, index) => (
            <div style={{ marginTop: "1rem", marginLeft: "1rem" }}>
              {obj.operator ? (
                <div style={{ marginTop: "1.6rem" }}>
                  <label>{obj.operator}</label>
                </div>
              ) : (
                <TextFieldWithLabel
                  type={obj.type}
                  label={obj.label}
                  value={counters[index]}
                  defaultValue={obj.defaultValue}
                  width={obj.width}
                  disabled={obj.disabled}
                  onChange={(e) => updateValues(parseInt(e.target.value), index)}
                  startAdornment={
                    <InputAdornment position='start'>{obj.prefix}</InputAdornment>
                  }
                />
              )}
            </div>
          ))}
        </div>
      )}
      <div style={data.stringStyle}>
        <label>{data.string} </label>
      </div>
    </div>
  );
};

export default CustomPromoKpiCard;
