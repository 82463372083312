import React from "react";
import { makeStyles } from "@mui/styles";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";

const styles = (theme) => ({
	accordionSummaryRoot: {
		minHeight: "48px !important",
		cursor: "unset !important",
		// backgroundColor: "#7FAF9A",
		color: "white",
		fontWeight: 700,
		fontSize: 20,
	},
	accordionSummaryContent: {
		// margin: '12px 0 !important',
		margin: "0px 0 !important",
	},
	accordionDetails: {
		//  borderTop: `0.2px solid ${theme.palette.stroke}`,
		borderTop: `0px solid ${theme.palette.stroke}`,
	},
});

const useStyles = makeStyles(styles);

const CustomAccordion = (props) => {
	const { summary, details, expanded } = props;
	const style = useStyles();

	return (
		<Accordion
			style={{
				borderStyle: "groove",
				borderRadius: 5,
				boxShadow: "none",
			}}
			expanded={expanded}
		>
			<AccordionSummary
				classes={{
					root: style.accordionSummaryRoot,
					content: style.accordionSummaryContent,
				}}
			>
				{summary}
			</AccordionSummary>

			<hr />
			<AccordionDetails classes={{ root: style.accordionDetails }}>
				{details}
			</AccordionDetails>
		</Accordion>
	);
};

export default CustomAccordion;
