import React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Inventory2OutlinedIcon from "@mui/icons-material/Inventory2Outlined";
import ListComponent from "../List";
import Typography from "@mui/material/Typography";

const CustomisedCard = (props) => {
	let calc_array = [];
	if (props.item.perc_calc) {
		calc_array = [props.item.c2, props.item.c3].map(
			(val) => ((props.item.c1 - val) / props.item.c1).toFixed(2) * 100,
		);
	}
	return (
		<Card
			sx={{
				minWidth: 220,
				height: 90,
				margin: 3,
			}}
			
		>
			<CardContent sx={{ flex: "1 0 auto" }}>
				<div
					style={{
						display: "flex",
						justifyContent: "space-between",
					}}
				>
					<Typography sx={{ fontSize: 15, fontWeight: "normal" }} gutterBottom>
						{props.item.label}
					</Typography>

					{props.item.icon && (
						<i className={`fa ${props.item.icon}`} style={{ color: "gray" }} />
					)}
				</div>
				<div
					style={{
						display: "flex",
						justifyContent: "space-between",
					}}
				>
					{props.item.cardLabel && (
						<Typography sx={{ fontSize: 18, fontWeight: 500 }} color="#3089e4">
							{props.item.cardLabel}
						</Typography>
					)}
					{props.item.cardValue && (
						<Typography sx={{ fontSize: 14, fontWeight: 200 }}>
							&nbsp;{props.item.cardValue} <br />
							{props.item.otherCardValue}
						</Typography>
					)}
				</div>
				{props.item.l1 && (
					<ListComponent
						data={[
							{
								name: props.item.l1,
								count: props.item.c1,
								style: props.item.l1_style,
							},
							{
								name: props.item.l2,
								count: props.item.c2,
								style: props.item.l2_style,
								calc: props.item.perc_calc,
								calc_val: calc_array[0],
							},
							{
								name: props.item.l3,
								count: props.item.c3,
								style: props.item.l3_style,
								calc: props.item.perc_calc,
								calc_val: calc_array[1],
							},
						]}
					/>
				)}
			</CardContent>
		</Card>
	);
};

export default CustomisedCard;
