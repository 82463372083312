import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { FormControl, FormControlLabel, Grid } from "@mui/material";
import Button from "@mui/material/Button";
import { CustomModal } from "./customModal";
import AddIcon from "@mui/icons-material/Add";
import Switch from "@mui/material/Switch";
import EditIcon from "@mui/icons-material/Edit";
import ListIcon from "@mui/icons-material/List";
import UpdateIcon from "@mui/icons-material/Update";
import DeleteIcon from "@mui/icons-material/Delete";
import DownloadIcon from "@mui/icons-material/Download";
import ExpandCircleDownIcon from "@mui/icons-material/ExpandCircleDown";
import { groupBy, mapValues } from "lodash";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import DescriptionIcon from "@mui/icons-material/Description";
import SnackBar from "../../../components/SnackBar";
import DownloadFiles from "./DownloadFiles";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import _ from "lodash";
import { addNotifications } from "../data";
import ButtonDropdown from "../CustomComponents/ButtonDropdown";
const CustomActionButton = (props) => {
  const { data } = props;
  const {
    selectedRowInfo,
    tableInfo,
    editActionInfo,
    dependentInfo,
    formEditedValues,
  } = useSelector((state) => state.home);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [itemIndex, setItemIndex] = useState(null);
  const [showMessage, setShowMessage] = useState(false);
  const [message, setMessage] = useState("");
  const onAction = (action, index) => {
    if (action.actionName === "navigate") {
      if (action.otherActionName === "notifications") {
        addNotifications(action);
      }

      navigate(process.env.REACT_APP_BASE_ROUTE + action.path);
      if (action.sortBasedOnDropdown === "SORT_BASED_ON_DROPDOWN") {
        let tableParams = {};
        let tableData = [];
        if (dependentInfo[`${action.key1}`]) {
          tableData = tableInfo[`${action.parent_table_key}_${action.key1}`];
        }
        if (dependentInfo[`${action.key2}`]) {
          tableData = tableInfo[`${action.parent_table_key}_${action.key2}`];
        }
        tableParams[`${action.parent_table_key}`] = tableData;
        dispatch({
          type: "TABLE_DATA",
          payload: tableParams,
        });
      }
    } else if (action.actionName === "modal") {
      setOpen(!open);
      setItemIndex(index);
      if (action.isStoreRequired) {
        let params = {};
        params[`${action.key}`] = props.otherInfo.uniqueKey;
        dispatch({
          type: "FORM_EDITED_VALUES",
          payload: params,
        });
      }
    } else if (action.actionName === "Download") {
      dispatch({
        type: "EXPORT_TO_EXCEL",
        payload: true,
      });
    } else if (action.actionName === "click") {
      let params = {};

      params[`${action.key}`] = dependentInfo[`${action.key}`]
        ? !dependentInfo[`${action.key}`]
        : true;
      if (action.otherKey && dependentInfo[`${action.otherKey}`]) {
        params[`${action.otherKey}`] = dependentInfo[`${action.otherKey}`]
          ? !dependentInfo[`${action.otherKey}`]
          : true;
      } else {
        // if(action.otherKey){
        params[`${action.otherKey}`] = true;
        // }
      }
      if (action["defaultToggle"]) {
        params[`${action.key}`] = true;
      }
      dispatch({
        type: "DEPENDENT_COMPONENTS",
        payload: params,
      });
    } else if (action.actionName === "tab-redirect") {
      if (action.path) {
        navigate(process.env.REACT_APP_BASE_ROUTE + action.path);
      }
      dispatch({
        type: "TAB_REDIRECT",
        payload: {
          activeTabIndex: action.activeTabIndex,
          parentTabIndex: action.parentTabIndex,
          activeTabValue: action.activeTabValue,
          parentTabValue: action.parentTabValue,
        },
      });
    } else if (action.actionName === "RECALCULATE_IA_FORECAST") {
      const metricData =
        tableInfo["tb_plan_department_recalculate_ia_forecast"];
      let payload = {};
      payload[`tb_plan_department`] = [...metricData];
      dispatch({
        type: "TABLE_DATA",
        payload: payload,
      });
    } else if (action.actionName === "STYLE_SHOW_HIDE") {
      let params = {};
      params[`${action.key}`] = dependentInfo[`${action.key}`]
        ? !dependentInfo[`${action.key}`]
        : true;
      if (action.otherKey && dependentInfo[`${action.otherKey}`]) {
        params[`${action.otherKey}`] = dependentInfo[`${action.otherKey}`]
          ? !dependentInfo[`${action.otherKey}`]
          : true;
      } else {
        params[`${action.otherKey}`] = true;
      }
      params[`style-save`] = false;
      params[`add-style-button`] = false;
      params[`action-bar-2`] = true;
      dispatch({
        type: "DEPENDENT_COMPONENTS",
        payload: params,
      });
    } else if (action.actionName === "STYLE_SHOW_HIDE-2") {
      let params = {};
      params[`${action.key}`] = dependentInfo[`${action.key}`]
        ? !dependentInfo[`${action.key}`]
        : true;
      if (action.otherKey && dependentInfo[`${action.otherKey}`]) {
        params[`${action.otherKey}`] = dependentInfo[`${action.otherKey}`]
          ? !dependentInfo[`${action.otherKey}`]
          : true;
      } else {
        params[`${action.otherKey}`] = true;
      }
      params[`style-save-2`] = false;
      params[`add-style-button-2`] = false;
      params[`action-bar-2`] = true;
      dispatch({
        type: "DEPENDENT_COMPONENTS",
        payload: params,
      });
    } else if (action.actionName === "RECOMMENDED_TABLE_SHOW_HIDE") {
      let params = {};
      params[`${action.key}`] = dependentInfo[`${action.key}`]
        ? !dependentInfo[`${action.key}`]
        : true;
      if (action.otherKey && dependentInfo[`${action.otherKey}`]) {
        params[`${action.otherKey}`] = dependentInfo[`${action.otherKey}`]
          ? !dependentInfo[`${action.otherKey}`]
          : true;
      } else {
        params[`${action.otherKey}`] = true;
      }
      params[`recommended-table-1`] = false;
      params[`recommended-table-2`] = true;
      params[`store_list_new_col`] = false;
      params[`recommended-labels`] = true;
      dispatch({
        type: "DEPENDENT_COMPONENTS",
        payload: params,
      });
    } else if (action.actionName === "BUTTON_VALUE_CLICK") {
      let params = {};
      params[`${action.key}`] = dependentInfo[`${action.key}`]
        ? !dependentInfo[`${action.key}`]
        : true;
      if (action.otherKey && dependentInfo[`${action.otherKey}`]) {
        params[`${action.otherKey}`] = dependentInfo[`${action.otherKey}`]
          ? !dependentInfo[`${action.otherKey}`]
          : true;
      } else {
        params[`${action.otherKey}`] = true;
      }
      params[`store_list_new_col`] = true;
      params[`recommended-table-1`] = false;
      params[`recommended-labels`] = true;
      dispatch({
        type: "DEPENDENT_COMPONENTS",
        payload: params,
      });
    } else if (action.actionName === "CLOSE_BUTTON_CLICK") {
      let params = {};
      params[`${action.key}`] = dependentInfo[`${action.key}`]
        ? !dependentInfo[`${action.key}`]
        : true;
      if (action.otherKey && dependentInfo[`${action.otherKey}`]) {
        params[`${action.otherKey}`] = dependentInfo[`${action.otherKey}`]
          ? !dependentInfo[`${action.otherKey}`]
          : true;
      } else {
        params[`${action.otherKey}`] = true;
      }
      params[`store_list_new_col`] = false;
      params[`recommended-table-1`] = true;
      params[`recommended-table-2`] = false;
      params[`recommended-labels`] = false;
      dispatch({
        type: "DEPENDENT_COMPONENTS",
        payload: params,
      });
    } else if (action.actionName === "delete") {
      dispatch({
        type: "DELETE_SELECTED_TABLE_ROW",
        payload: true,
      });
      const tableData = tableInfo[`${action.parent_table_key}`];
      const selectedRowIds = selectedRowInfo[`${action.parent_table_key}`].map(
        (obj) => obj.id
      );
      const updatedData = tableData.filter((obj) => {
        if (selectedRowIds.indexOf(obj.id) === -1) {
          return obj;
        }
      });
      let payload = {};
      payload[`${action.parent_table_key}`] = [...updatedData];
      dispatch({
        type: "TABLE_DATA",
        payload: payload,
      });
    } else if (action.actionName === "edit") {
      let params = {};
      if (editActionInfo[`${action.parent_table_key}`]) {
        params[`${action.parent_table_key}`] = null;
      } else {
        params[`${action.parent_table_key}`] = true;
      }
      dispatch({
        type: "EDIT_ACTION",
        payload: params,
      });
    } else if (action.actionName === "Add") {
      let params = {};
      const newRow = [];
      const tableData = tableInfo[`${action.parent_table_key}`];
      if (tableData && tableData.length > 0) {
        let rowObj = {};
        Object.keys(tableData[0]).forEach((obj) => {
          rowObj[`${obj}`] = null;
          rowObj[`id`] = tableData.length + 1;
          rowObj[`department`] = "Skin Care";
          rowObj[`type`] = "Department";
        });
        newRow.push(rowObj);
      } else {
        newRow.push(action.defaultValue);
      }
      params[`${action.parent_table_key}`] = [...tableData, ...newRow];
      dispatch({
        type: "TABLE_DATA",
        payload: params,
      });
    } else if (action.actionName === "REFRESH_DATA") {
      let params = {};
      const newRow = [];
      const tableData = tableInfo[`${action.parent_table_key}`];
      params[`${action.source}`] = tableInfo[`${action.parent_table_key}`];
      dispatch({
        type: "TABLE_DATA",
        payload: params,
      });
    } else if (action.actionName === "displayMessage") {
      setMessage(action.message);
      setShowMessage(true);
      if (action.otherActionName === "notifications") {
        addNotifications(action);
      }
      if (action.sortBasedOnDropdown === "SORT_BASED_ON_DROPDOWN") {
        let tableParams = {};
        let tableData = [];
        if (dependentInfo[`${action.key1}`]) {
          tableData = tableInfo[`${action.parent_table_key}_${action.key1}`];
        }
        if (dependentInfo[`${action.key2}`]) {
          tableData = tableInfo[`${action.parent_table_key}_${action.key2}`];
        }
        tableParams[`${action.parent_table_key}`] = tableData;
        dispatch({
          type: "TABLE_DATA",
          payload: tableParams,
        });
      }
    } else if (action.actionName === "SHOW_CLUSTER") {
      let params = {};
      params[`showClusterLevelPLan`] = true;
      params[`dinnerware-store-cluster`] = true;
      dispatch({
        type: "DEPENDENT_COMPONENTS",
        payload: params,
      });
    } else if (action.actionName === "APPLY_FILTER_ACTION") {
      let params = {};
      params[`apply-action`] = true;
      params[`default-key`] = false;
      dispatch({
        type: "DEPENDENT_COMPONENTS",
        payload: params,
      });
    } else if (action.actionName === "IN_SEASON_RECALCULATE_IA_FORECAST") {
      const metricData = tableInfo[`${action.source}`];
      let payload = {};
      payload[`${action.dest}`] = [...metricData];
      dispatch({
        type: "TABLE_DATA",
        payload: payload,
      });
    } else if (action.actionName === "notifications") {
      addNotifications(action);
    } else if (action.actionName === "DATA_REFRESH_BASED_ON_DROPDOWN") {
      let payload = {};
      let sourceTableData = tableInfo[`${action.source_table_key}`];
      action.toggleOptions.forEach((obj) => {
        if (action["isBasedOnStore"]) {
          if (dependentInfo[`${obj}`]) {
            sourceTableData = tableInfo[`${action[`${obj}`]}`];
          }
        } else {
          if (formEditedValues[`${obj}`]) {
            sourceTableData = tableInfo[`${action[`${obj}`]}`];
          }
        }
      });
      payload[`${action.parent_table_key}`] = sourceTableData;
      dispatch({
        type: "TABLE_DATA",
        payload: payload,
      });
    } else if (action.actionName === "TAB_REDIRECT_BASED_ON_DROPDOWN") {
      if (action.path) {
        navigate(process.env.REACT_APP_BASE_ROUTE + action.path);
      }
      if (
        action["isBasedOnDropdownKey"] &&
        formEditedValues[`${action.key}`] === action["isBasedOnDropdownKey"]
      ) {
        dispatch({
          type: "TAB_REDIRECT",
          payload: {
            activeTabIndex: action.activeTabIndex,
            parentTabIndex: action.parentTabIndex,
            activeTabValue: action.activeTabValue,
            parentTabValue: action.parentTabValue,
            isDependentOn: action.isDependentOn,
          },
        });
        setTimeout(() => {
          dispatch({
            type: "RESET_TAB_REDIRECT",
            payload: {},
          });
        }, 15000);
      }
    } else if (action.actionName === "showActiveTab") {
      dispatch({
        type: "SHOW_ACTIVE_TAB",
        payload: action,
      });
    } else if (action.actionName === "redirectNewTab") {
      dispatch({
        type: "REDIRECT_TO_NEW_TAB",
        payload: action,
      });
    } else if (action.actionName === "COPY_ONE_TABLE_TO_ANOTHER") {
      const sourceData = tableInfo[`${action.source_table_key}`];
      let payload = {};
      payload[`${action.parent_table_key}`] = [...sourceData];
      dispatch({
        type: "TABLE_DATA",
        payload: payload,
      });
      if (action.path) {
        navigate(process.env.REACT_APP_BASE_ROUTE + action.path);
      }
    } else if (action.actionName === "APPEND_NEW_DATA_TO_TABLE") {
      const sourceData = tableInfo[`${action.source_table_key}`];
      const parentData = tableInfo[`${action.parent_table_key}`];
      let payload = {};
      payload[`${action.parent_table_key}`] = [...parentData, ...sourceData];
      dispatch({
        type: "TABLE_DATA",
        payload: payload,
      });
    } else if (action.actionName === "FILTER_DATA_BASED_ON_KEY"){
      let params = {};
      const newRow = [];
      const tableData = tableInfo[`${action.source}`];
      console.log("Table Data",tableData)
      // const updatedData = tableData.filter(obj=> obj[`${action.mappingKey}`].includes(`(${formEditedValues[`${action.data_key}`]}%)`) )

      const updatedData = tableData.filter(obj=> {
        return obj[`${action.mappingKey}`] >= formEditedValues[`${action.data_key}`];
      } )
      params[`${action.destination}`] = updatedData;
      console.log("=======",params,tableInfo[`${action.source}`])
      dispatch({
        type: "TABLE_DATA",
        payload: params,
      });
    }else if (action.actionName === "RESET_DATA"){
      let params = {};
      const tableData = tableInfo[`${action.source}`];
      params[`${action.destination}`] = tableData;
      dispatch({
        type: "TABLE_DATA",
        payload: params,
      });
    }else {
      dispatch({
        type: "DISPLAY_TABLE",
        payload: action.key,
      });
    }
  };

  const onIconClickAction = (item, index) => {
    const { action } = item;
    const { parent_table_key, unique_key, add_values } = item.action;
    let params = {};
    if (action.actionName === "click") {
      if (editActionInfo[`${item.parent_table_key}`]) {
        params[`${item.parent_table_key}`] = null;
      } else {
        params[`${item.parent_table_key}`] = true;
      }
      dispatch({
        type: "EDIT_ACTION",
        payload: params,
      });
    } else if (action.actionName === "navigate") {
      navigate(process.env.REACT_APP_BASE_ROUTE + action.path);
    } else if (action.actionName === "AddNewRowBasedOnEdit") {
      if (tableInfo[`${parent_table_key}`]) {
        let selectedRowId = null;

        if (selectedRowInfo[`${parent_table_key}`]) {
          selectedRowId = selectedRowInfo[`${parent_table_key}`].map(
            (obj) => obj[`${unique_key}`]
          );
        }
        const insertedData = [];
        const selectedData = selectedRowInfo[`${parent_table_key}`]
          ? selectedRowInfo[`${parent_table_key}`]
          : tableInfo[`${parent_table_key}`];
        selectedData.filter((obj, index) => {
          if (index === 0) {
            let params = {};
            Object.keys(obj).forEach((nestedObj) => {
              params[`${nestedObj}`] = null;
            });
            params[`${unique_key}`] = obj[`${unique_key}`];
            insertedData.push({ ...params, ...add_values });
          }
        });
        let requestData = {};
        requestData[`${parent_table_key}`] = [
          ...tableInfo[`${parent_table_key}`],
          ...insertedData,
        ];
        dispatch({
          type: "TABLE_DATA",
          payload: requestData,
        });
      }
    } else if (action.actionName === "modal") {
      setOpen(!open);
      setItemIndex(index);
    } else if (action.actionName === "toggle") {
      params[`${action.key}`] = dependentInfo[`${action.key}`]
        ? !dependentInfo[`${action.key}`]
        : true;
      if (action.otherKey && dependentInfo[`${action.otherKey}`]) {
        params[`${action.otherKey}`] = dependentInfo[`${action.otherKey}`]
          ? !dependentInfo[`${action.otherKey}`]
          : true;
      }
      dispatch({
        type: "DEPENDENT_COMPONENTS",
        payload: params,
      });
    }
  };
  const handleClose = () => {
    setOpen(false);
  };

  const getIcon = (item, index) => {
    const { parent_table_key, iconType } = item;
    switch (iconType) {
      case "Add":
        return (
          <>
            {parent_table_key ? (
              <>
                {selectedRowInfo[`${parent_table_key}`] &&
                editActionInfo[`${parent_table_key}`] ? (
                  <Button
                    style={{ textTransform: "none", margin: 5 }}
                    variant={item.varient}
                    size={item.size}
                    title={item.name}
                    onClick={() => onIconClickAction(item, index)}
                  >
                    <AddIcon />
                  </Button>
                ) : (
                  <></>
                )}
              </>
            ) : (
              <AddIcon />
            )}
          </>
        );
        break;
      case "Edit":
        return (
          <>
            {parent_table_key ? (
              <>
                {selectedRowInfo[`${parent_table_key}`] &&
                editActionInfo[`${parent_table_key}`] ? (
                  <Button
                    style={{ textTransform: "none", margin: 5 }}
                    variant={item.varient}
                    size={item.size}
                    title={item.name}
                    onClick={() => onIconClickAction(item, index)}
                  >
                    <UpdateIcon />
                  </Button>
                ) : (
                  <>
                    <Button
                      style={{ textTransform: "none", margin: 5 }}
                      variant={item.varient}
                      size={item.size}
                      title={item.name}
                      onClick={() => onIconClickAction(item, index)}
                    >
                      <EditIcon />
                    </Button>
                  </>
                )}
              </>
            ) : null}
          </>
        );
        break;
      case "Delete":
        return (
          <>
            {parent_table_key ? (
              <>
                {selectedRowInfo[`${parent_table_key}`] &&
                editActionInfo[`${parent_table_key}`] ? null : (
                  <>
                    <Button
                      style={{ textTransform: "none", margin: 5 }}
                      variant={item.varient}
                      size={item.size}
                      title={item.name}
                      onClick={() => onIconClickAction(item, index)}
                    >
                      <DeleteIcon />
                    </Button>
                  </>
                )}
              </>
            ) : null}
          </>
        );
        break;
      case "List":
        return <ListIcon />;
        break;
      case "Copy":
        return <ContentCopyIcon />;
      case "EditIcon":
        return <EditIcon />;
      case "AddNewRowBasedOnEdit":
        return (
          <>
            {editActionInfo[`${parent_table_key}`] || item.default_open ? (
              <>
                <Button
                  style={{ textTransform: "none", margin: 5 }}
                  variant={item.varient}
                  size={item.size}
                  title={item.name}
                  onClick={() => onIconClickAction(item, index)}
                >
                  <AddIcon />
                </Button>
              </>
            ) : null}
          </>
        );

        break;

      case "Update":
        return editActionInfo[`${parent_table_key}`] ? (
          <>
            <Button
              style={{ textTransform: "none", margin: 5 }}
              variant={item.varient}
              size={item.size}
              title={item.name}
              onClick={() => onIconClickAction(item, index)}
            >
              <UpdateIcon />
            </Button>
          </>
        ) : null;
        break;
      case "Download":
        return <DownloadIcon />;
      case "ExpandCircleDownIcon":
        return <ExpandCircleDownIcon />;
      default:
        return <AddIcon />;
        break;
    }
  };

  const label = { inputProps: { "aria-label": "Switch demo" } };

  const handleToggle = (e, item) => {
    const { name, value } = e.target;
    switch (item.type) {
      case "UPDATE_CURRENT_ROW":
        if (tableInfo[`${item.parent_table_key}`]) {
          let selectedRowId = props.otherInfo.data[`${item.unique_key}`];

          const updatedData = tableInfo[`${item.parent_table_key}`].filter(
            (obj) => {
              if (obj[`${item.unique_key}`] == selectedRowId) {
                if (obj["include_unmapped_products"] === "TRUE") {
                  obj[`products_mapped`] = obj[`old_products_mapped`];
                } else {
                  obj[`old_products_mapped`] = obj[`products_mapped`];
                  obj[`products_mapped`] = "100/100";
                }
                obj["include_unmapped_products"] =
                  obj["include_unmapped_products"] === "TRUE"
                    ? "FALSE"
                    : "TRUE";
                return obj;
              }
              return obj;
            }
          );
          let params = {};
          params[`${item.parent_table_key}`] = [...updatedData];
          dispatch({
            type: "TABLE_DATA",
            payload: params,
          });
        }

        break;
      case "UPDATE_CURRENT_ROW_VALUE":
        if (
          tableInfo[`${item.parent_table_key}`] &&
          tableInfo[`${item.child_table_key}`]
        ) {
          let selectedRowId = props.otherInfo.data[`${item.unique_key}`];

          // const updatedData =  tableInfo[`${item.parent_table_key}`].filter(obj=> {
          //   if(obj[`${item.unique_key}`] == selectedRowId){

          //     let value = parseInt(obj[`products_mapped`].split("/")[0])
          //       obj["products_mapped"] =  value + "/" +"100"
          //     return obj;
          //   }
          //   return obj
          // })
          const childTable = tableInfo[`${item.child_table_key}`].filter(
            (obj) => {
              if (obj[`${item.unique_key}`] == selectedRowId) {
                obj["mapping"] = obj["mapping"] === "TRUE" ? "FALSE" : "TRUE";
                return obj;
              }
              return obj;
            }
          );
          let params = {};
          params[`${item.child_table_key}`] = [...childTable];
          // dispatch({
          //   type: "TABLE_DATA",
          //   payload: params,
          //   });
        }
      default:
        break;
    }
  };
  const renderIcons = (obj) => {
    switch (obj.type) {
      case "Edit":
        return <EditIcon />;
        break;
      case "Copy":
        return <ContentCopyIcon />;
        break;
      default:
        break;
    }
  };

  const getActionType = (item, index) => {
    switch (props.otherInfo.actionType) {
      case "link":
        return (
          <a
            href="javascript:void(0)"
            onClick={() => onAction(item.action, index)}
            style={{ textDecoration: "none" }}
          >
            {props.otherInfo.isLabelAction}
          </a>
        );
        break;
      case "toggle":
        return (
          <>
            {/* <FormControlLabel control={<Switch defaultChecked 
          checked={props.otherInfo.isLabelAction === "TRUE" } onClick={(e)=>{
            handleToggle(e,item)
          }} />} label={""} />  */}
            <Switch
              {...label}
              checked={props.otherInfo.isLabelAction === "TRUE"}
              onClick={(e) => {
                handleToggle(e, item);
              }}
            />
          </>
        );
        break;
      case "switch":
        return (
          <>
            {props.label}{" "}
            <FormControlLabel control={<Switch />} label={props.endLabel} />
          </>
        );
      case "actions-list":
        return (
          <>
            {/* <span style={{paddingTop:"22px"}}>
            {  props.data.details.map(obj=>(
              <>
               {renderIcons(obj)} &nbsp;&nbsp;
              </>
             ))  }
              
            </span> */}
          </>
        );

      default:
        return (
          <>
            {["Copy"].indexOf(props.otherInfo.isLabelAction) !== -1 ? (
              <>
                {props.data.details?.map((item, index) => (
                  <>
                    {/* {renderIcons(item)} */}
                    <EditIcon sx={{ cursor: "pointer" }} /> &nbsp; &nbsp;{" "}
                    <ContentCopyIcon
                      sx={{ cursor: "pointer" }}
                      onClick={() => {
                        onAction(item.action, index);
                      }}
                    />{" "}
                    &nbsp; &nbsp;
                    <DescriptionIcon sx={{ cursor: "pointer" }} /> &nbsp; &nbsp;{" "}
                  </>
                ))}
              </>
            ) : (
              <>
                <a
                  href="javascript:void(0)"
                  onClick={() => onAction(item.action, index)}
                  style={{ textDecoration: "none" }}
                >
                  {props.otherInfo.isLabelAction}
                </a>
              </>
            )}
          </>
        );
        break;
    }
  };

  const handleMessageClose = () => {
    setShowMessage(false);
  };
  return (
    <>
      {open ? (
        <>
          <CustomModal
            show={open}
            {...data.details[itemIndex]["action"]}
            handleClose={handleClose}
          />
        </>
      ) : null}
      {showMessage && (
        <>
          <SnackBar
            message={message}
            variant="success"
            handleClose={handleMessageClose}
          />
        </>
      )}
      <Grid>
        <div style={{ textAlign: data && data.alignment, ...data.style }}>
          {data.details?.map((item, index) => (
            <>
              {props.otherInfo && props.otherInfo.isLabelAction ? (
                <>{getActionType(item, index)}</>
              ) : (
                <>
                  {item.isIcon ? (
                    <>
                      {item.parent_table_key ? (
                        <>
                          {selectedRowInfo[`${item.parent_table_key}`] && (
                            // <Button
                            //   style={{ textTransform: "none", margin: 5 }}
                            //   variant={item.varient}
                            //   size={item.size}
                            //   title={item.name}
                            //   // disabled={obj.disableFeat && diable}
                            //   onClick={() => onIconClickAction(item, index)}
                            // >
                            //   {getIcon(item)}
                            // </Button>
                            <>{getIcon(item, index)}</>
                          )}
                        </>
                      ) : (
                        <>
                          {getIcon(item, index) !== null ? (
                            <Button
                              style={{
                                textTransform: "none",
                                margin: 5,
                                ...item.style,
                              }}
                              variant={item.varient}
                              size={item.size}
                              title={item.name}
                              // disabled={obj.disableFeat && diable}
                              onClick={() => onIconClickAction(item, index)}
                            >
                              {item.iconLabel}
                              {getIcon(item, index)}
                            </Button>
                          ) : null}
                        </>
                      )}
                    </>
                  ) : (
                    <>
                      {item["isDisabledBasedOnRowSelection"] ||
                      item["isDisabledBasedOnOther"] ? (
                        <>
                          <Button
                            style={{
                              textTransform: "none",
                              margin: 5,
                              ...item.style,
                            }}
                            variant={item.varient}
                            size={item.size}
                            title={item.name}
                            // disabled={obj.disableFeat && diable}
                            onClick={() => onAction(item.action, index)}
                            disabled={
                              (item.isDisabledBasedOnRowSelection
                                ? selectedRowInfo[
                                    `${item.isDisabledBasedOnRowSelection}`
                                  ] &&
                                  selectedRowInfo[
                                    `${item.isDisabledBasedOnRowSelection}`
                                  ].length > (item.selectedRowscount || 0)
                                  ? false
                                  : true
                                : false) ||
                              (item.isDisabledBasedOnOther &&
                                !dependentInfo[
                                  `${item.isDisabledBasedOnOther}`
                                ])
                            }
                          >
                            {item.name}
                          </Button>
                        </>
                      ) : (
                        <>
                          {item.type === "download" ? (
                            <>
                              <DownloadFiles {...item} />
                            </>
                          ) : (
                            <>
                              {item[`dependentOn`] ? (
                                <>
                                  {dependentInfo[`${item[`dependentOn`]}`] ? (
                                    <>
                                      {item["type"] === "buttonDropdown" ? (
                                        <ButtonDropdown
                                          data={item}
                                          isDivNotRequired={true}
                                        />
                                      ) : (
                                        <Button
                                          style={{
                                            textTransform: "none",
                                            margin: 5,
                                            width: item.width,
                                          }}
                                          variant={item.varient}
                                          size={item.size}
                                          title={item.name}
                                          // disabled={obj.disableFeat && diable}
                                          onClick={() =>
                                            onAction(item.action, index)
                                          }
                                        >
                                          {item.iconType
                                            ? getIcon(item, index)
                                            : null}{" "}
                                          &nbsp;&nbsp;{item.name}
                                        </Button>
                                      )}
                                    </>
                                  ) : null}
                                </>
                              ) : (
                                <>
                                  <Button
                                    style={{
                                      textTransform: "none",
                                      margin: 5,
                                      width: item.width,
                                    }}
                                    variant={item.varient}
                                    size={item.size}
                                    title={item.name}
                                    // disabled={obj.disableFeat && diable}
                                    onClick={() => onAction(item.action, index)}
                                  >
                                    {item.iconType
                                      ? getIcon(item, index)
                                      : null}{" "}
                                    &nbsp;&nbsp;{item.name}
                                  </Button>
                                </>
                              )}
                            </>
                          )}
                        </>
                      )}
                    </>
                  )}
                </>
              )}
            </>
          ))}
        </div>
      </Grid>
    </>
  );
};

export default CustomActionButton;
