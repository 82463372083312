import { Step, StepButton, Stepper } from '@mui/material';


const Steppers = (props) => {
  return (
    <Stepper nonLinear activeStep={props.data.activeStep} alternativeLabel>
      {props.data.steps.map((label, index) => (
        <Step key={label}>
          <StepButton>{label}</StepButton>
        </Step>
      ))}
    </Stepper>
  );
};

export default Steppers;
