import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import { Chip } from "@mui/material";

const useStyles = makeStyles({
	summaryContainer: {
		border: `1px solid lightgray`,
		margin: `1rem 0`,
		padding: `0.5rem`,
		borderRadius: 10,
		fontSize: "12px",
		backgroundColor: "white",
	},
	summaryChip: {
		margin: "0 0.1rem",
	},
	dimension: {
		margin: "0px 20px 0px 10px",
	},
});
const InfoPanel = ({ data }) => {
	const classes = useStyles();
	const getChips = (dimension) => {
		if (dimension.label) {
			return (
				<span className={classes.dimension}>
					{dimension.label}
					{dimension?.section?.split(",")?.map((item) => (
						<Chip className={classes.summaryChip} label={item} />
					))}
				</span>
			);
		}
	};

	return (
		<div className={classes.summaryContainer}>
			{data.map((dimension) => getChips(dimension))}
		</div>
	);
};

export default InfoPanel;
