import React from "react";
import { Grid, Card } from "@mui/material";
import CustomKpiCard from "../../../components/CustomKpiCard";
import CustomPromoKpiCard from "../../../components/CustomPromoKpiCard";

const KpiCardComponent = (props) => {
  const { data } = props;

  return (
    <Grid container spacing={2}>
      {data.data.map((obj, index) => (
        <Grid item xs={12} md={data.style.md} key={`data-card-${index}`}>
          <div style={data.style}>
          <Card>
            {data.uniqueKey === "Promo" ? <CustomPromoKpiCard data={obj} values={data} /> : <CustomKpiCard data={obj} values={data} />}
          </Card>
          </div>
        </Grid>
      ))}
    </Grid>
  );
};

export default KpiCardComponent;
