import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import { CustomModal } from "./customModal";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import PopupState, { bindTrigger, bindMenu } from "material-ui-popup-state";
import { addNotifications } from "../data";
import SnackBar from "../../../components/SnackBar";


export default function MenuPopupState(props) {
	const { data } = props;
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [open, setOpen] = useState(false);
	const [message, setMessage] = useState(null)
	const [showMessage, setShowMessage] = useState(false)
	const [itemIndex, setItemIndex] = useState(null);
	const { apiData, dependentInfo, tableInfo, selectedRowInfo } = useSelector(
		(state) => state.home,
	);
	const onAction = (action, index, popupState) => {
		if (action.actionName === "navigate") {
			if(action.otherActionName === 'notifications'){
				addNotifications(action)
			}
			navigate(process.env.REACT_APP_BASE_ROUTE + action.path);
		} else if (action.actionName === "modal") {
			setOpen(!open);
			setItemIndex(index);
		} else if (action.actionName === "click") {
			let params = {};
			params[`${action.key}`] = dependentInfo[`${action.key}`]
				? !dependentInfo[`${action.key}`]
				: true;
			if (action.otherKey && dependentInfo[`${action.otherKey}`]) {
				params[`${action.otherKey}`] = dependentInfo[`${action.otherKey}`]
					? !dependentInfo[`${action.otherKey}`]
					: true;
			} else {
				params[`${action.otherKey}`] = true;
			}
			dispatch({
				type: "DEPENDENT_COMPONENTS",
				payload: params,
			});
		} else if (action.actionName === "DROPDOWN_DINNERWARE_ACTION") {
			let params = {};
			params[`plate-store-cluster`] = false;
			params[`dinnerware-store-cluster`] = true;
			dispatch({
				type: "DEPENDENT_COMPONENTS",
				payload: params,
			});
		} else if (action.actionName === "DROPDOWN_PLATE_ACTION") {
			let params = {};
			params[`dinnerware-store-cluster`] = false;
			params[`plate-store-cluster`] = true;
			dispatch({
				type: "DEPENDENT_COMPONENTS",
				payload: params,
			});
		}
		else if (action.actionName === "displayMessage") {
			setShowMessage(true)
			setMessage(action.message)

		}
		popupState.close();
	};

	const handleClose = () => {
		setOpen(false);
	};

	const handleMessageClose = () => {
		setShowMessage(false);
		setMessage(null)
		
	  };
	const renderPopupVIew = ()=>{
		return (
			<>
			     {showMessage && (
					<>
					<SnackBar
						message={message}
						variant="success"
						handleClose={handleMessageClose}
					/>
					</>
				)}
				<PopupState variant="popover" popupId="demo-popup-menu">
					{(popupState) => (
						<React.Fragment>
							<Button
								style={{ textTransform: "none", margin: 5, width: data.width }}
								variant={data.variant || "contained"}
								{...bindTrigger(popupState)}
							>
								{data.label}
							</Button>
							<Menu {...bindMenu(popupState)}>
								{data.options.map((option, index) => (
									<>
										{option.dependentOn ? (
											<>
												{" "}
												{dependentInfo[`${option.dependentOn}`] ? null : (
													<>
														<MenuItem
															onClick={() => onAction(option.action, index)}
														>
															{option.name}
														</MenuItem>
													</>
												)}{" "}
											</>
										) : (
											<>
												<MenuItem
													onClick={() =>
														onAction(option.action, index, popupState)
													}
												>
													{option.name}
												</MenuItem>
											</>
										)}{" "}
									</>
								))}
							</Menu>
						</React.Fragment>
					)}
				</PopupState>
			</>
		)
	}
	return (
		<>
			{open ? (
				<>
					<CustomModal
						show={open}
						{...data.options[itemIndex]["action"]}
						handleClose={handleClose}
					/>
				</>
			) : null}
			{props['isDivNotRequired'] ? (
				<>
				{renderPopupVIew()}
				</>
			):(
				<>
				<div style={{ textAlign: data && data.alignment }}>
					{renderPopupVIew()}
				</div>
				</>
			)}
			
		</>
	);
}
