import React from "react"
import { CircularProgress } from "@mui/material"

const alignment = {
    "left": { marginLeft: 0 },
    "right": { marginLeft: 'auto' },
    "center": {
        marginLeft: 'auto',
        marginRight: 'auto'
    }
}
function CircularProgressBar(props) {
    if (!props.data)
        return ''
    const { data } = props
    const props_primary = {
        variant: "determinate",
        value: data.details.fill,
        thickness: data.details.thickness,
        size: data.details.size,
        sx: { color: data.details.color_id }
    }
    const props_secondary = {
        ...props_primary,
        value: 100,
        sx: { color: 'grey', opacity: 0.1 }
    }
    return (
        <div className="progress" style={alignment[data.details.alignment]}>
            <div style={{ position: 'relative' }}>
                <CircularProgress {...props_primary} />
                <CircularProgress className="secondary-bar" {...props_secondary} />
            </div>
            <p className='progress--text'>{data.details.text}</p>
        </div>
    )
}
export default CircularProgressBar