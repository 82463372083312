import React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ComponentResolver from "../Resolver/ComponentResolver";

const CustomAccordian = (props) => {
  const { data } = props;

  return (
    <>
    <div>
      <Accordion
        style={{
            borderLeft: "2px solid rgb(0, 85, 175)",
        }}
        disabled={data.disabled}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>{data.label}</Typography>
        </AccordionSummary>
        <AccordionDetails>
            <ComponentResolver pageObject={{ components: data.components }} />
        </AccordionDetails>
      </Accordion>
    </div>
    </>
  );
};
export default CustomAccordian;
