export const nrfWksPatternForAYr = [4, 4, 5, 4, 4, 5, 4, 4, 5, 4, 4, 5];
export const qtrWkMapping = [
	[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13],
	[14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26],
	[27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39],
	[40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52],
];
export const toggleOptions = [
	{ label: "Week", value: "week" },
	{ label: "Month", value: "month" },
	{ label: "Quarter", value: "quarter" },
	{ label: "Year", value: "year" },
];

// header cell mapping with calendar toggle view
export const headeCellView = {
	week: "day",
	month: "week",
	quarter: "week",
	year: "month",
};

export const eventStatusColors = {
	planned: {
		from: "#fff",
		to: "#d1ffe7",
		label: "Planned",
		title: "#000",
		timeline: "#5d5d5d",
		border: "#84c1ea",
	},
	ongoing: {
		from: "#fff",
		to: "#c7ecff",
		label: "Ongoing",
		title: "#000",
		timeline: "#5d5d5d",
		border: "#f09b38",
	},
	completed: {
		from: "#b1b1b2",
		to: "#ffe1c4",
		label: "Completed",
		title: "#e2e2e2",
		timeline: "#e2e2e2",
		border: "#b1b1b2",
	},
};
