import React, {
	useState,
	useCallback,
	useMemo,
	useRef,
	useEffect,
} from "react";
import { AgGridReact } from "ag-grid-react";
// import "ag-grid-community/dist/styles/ag-grid.css";
// import "ag-grid-community/dist/styles/ag-theme-balham.css";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";
import "./Table.scss";
import { theme } from "../../theme";
import {
	getColumnDefs,
	currencyFormatter,
	percentageFormatter,
	NoFormatter,
} from "./utils";
import { LicenseManager } from "ag-grid-enterprise";
import { fetchData, getMultipleModelsData } from "../../pages/Home/action";
import { CustomRendererComponent } from "../../pages/Home/CustomComponents/CustomRendererComponent";
import { useDispatch, useSelector } from "react-redux";
import { EditWeekData } from "../../pages/Home/CustomComponents/editWeekData";
import AddHideMetrics from "../../pages/Home/CustomComponents/ShowHideMetrics";
import { EditTextField } from "../../pages/Home/CustomComponents/editTextFields";
import CellTextField from "../../pages/Home/CustomComponents/CellTextField";
import CellImageField from "../../pages/Home/CustomComponents/CellImageField";
import CellPopOverImageField from "../../pages/Home/CustomComponents/CellPopOverImageField";
import DropdownCellRenderer from "../../pages/Home/CustomComponents/dropdownCellRender";
import CellSwitchField from "../../pages/Home/CustomComponents/CellSwitchField";
import { PreformattedText } from "../../pages/Home/CustomComponents/PreformattedText";
import CellAddIcon from "../../pages/Home/CustomComponents/CellAddIcon";
import CellMultiSelectDropdown from "../../pages/Home/CustomComponents/CellMultiSelectDropdown";
import CellChips from "../../pages/Home/CustomComponents/CellChips";
import CellColor from "../../pages/Home/CustomComponents/CellColor";

const LICENSE_KEY =
	"CompanyName=Impact Analytics,LicensedGroup=31Jan22 Purchase,LicenseType=MultipleApplications,LicensedConcurrentDeveloperCount=1,LicensedProductionInstancesCount=0,AssetReference=AG-025014,ExpiryDate=31_January_2023_[v2]_MTY3NTEyMzIwMDAwMA==e4f58ef1fe10261cf66aa1e5a5cb2da6";
LicenseManager.setLicenseKey(LICENSE_KEY);

const Table = (props) => {
	const {
		headerHeight,
		rowHeight,
		createExtraColDefs,
		filterColumns,
		filterColumnId,
		autoHeight,
		filterColumn,
	} = props;
	const dispatch = useDispatch();
	const {
		deleteTableRow,
		isExportTable,
		selectedRowInfo,
		tableInfo,
		columnsInfo,
		dependentInfo,
		tableValues,
		dropdownSelectionData,
	} = useSelector((state) => state.home);
	const gridRef = useRef();
	const [modal, setModal] = useState(false);
	const [columnDefs, setColumnDefs] = useState([props.columnDefs]);
	const [defaultColDef, setDefaultColDef] = useState({
		enableValue: true,
		enableRowGroup: true,
		enablePivot: true,
		sortable: true,
		filter: "agTextColumnFilter",
		editable: props.editable,
		resizable: true,
		minWidth: 100,
		autoHeight: props.autoHeight,
	});
	const [gridOptions, setGridOptions] = useState({
		rememberGroupStateWhenNewData: true,
		suppressScrollOnNewData: true,
	});
	const [context, setContext] = useState({
		editedColumns: [],
	});
	const [rowData, setRowData] = useState([]);
	const [gridApi, setGridApi] = useState({});
	const [gridColumnApi, setGridColumnApi] = useState({});

	useEffect(() => {
		const { apikey, isApiNotRequird } = props;
		if (apikey && !isApiNotRequird) {
			let reqParams = [apikey];
			let payload = {};
			if (
				tableInfo[`${apikey}`] &&
				tableInfo[`${apikey}`].length > 0 &&
				props.callNotRequiredIfDataExist
			) {
			} else if (!Object.keys(tableValues).includes(apikey)) {
				getMultipleModelsData({ model_names: reqParams }).then((res) => {
					setRowData([...res[apikey]]);
					payload[apikey] = [...res[apikey]];
					dispatch({
						type: "TABLE_VALUES",
						payload: payload,
					});
					if (props.isStoreRequired) {
						let params = {};
						params[`${apikey}`] = [...res[apikey]];
						if (dependentInfo[`${props.dependentOnTab}`]) {
							if (props.replaceOtherApi) {
								params[`${props.source}`] = [
									...tableInfo[`${props.replaceOtherApi}`],
								];
							}
						}
						if (props.isCopyRequired) {
							params[`${apikey}_copy`] = [...res[apikey]];
						}

						if (props.storeDataBasedoOnKey) {
							if (Object.keys(props.storeDataBasedoOnKey).length > 0) {
								const basedOnKeyData = res[apikey].filter((obj) => {
									if (
										obj[`${props.storeDataBasedoOnKey.key}`] ===
										props.storeDataBasedoOnKey.value
									) {
										return obj;
									}
								});
								params[`${apikey}`] = basedOnKeyData;
							}
						}
						dispatch({
							type: "TABLE_DATA",
							payload: params,
						});
					}
				});

				if (props.otherApiKeys) {
					setTimeout(() => {
						getMultipleModelsData({ model_names: props.otherApiKeys }).then(
							(res) => {
								if (props.isStoreRequired) {
									let params = {};
									if (props.otherApiKeys) {
										props.otherApiKeys.forEach((apiObj) => {
											params[`${apiObj}`] = [...res[apiObj]];
										});
									}
									dispatch({
										type: "TABLE_DATA",
										payload: params,
									});
								}
							},
						);
					}, 1000);
				}
				if (props.isColumnStoreRequired) {
					let columnParams = {};
					columnParams[`${apikey}`] = props.columnDefs;
					if (props.isCopyRequired) {
						columnParams[`${apikey}_copy`] = props.columnDefs;
					}
					dispatch({
						type: "COLUMN_DATA",
						payload: columnParams,
					});
				}

				if (props.showHideMetrics) {
					setGridOptions({
						...gridOptions,
						sideBar: sideBar,
					});
				}
			} else {
				setRowData(tableValues[apikey]);
			}
		}
	}, [props.apikey]);

	useEffect(() => {
		if (tableInfo[`${props.apikey}`]) {
			setRowData([...tableInfo[`${props.apikey}`]]);
			if (gridRef && gridRef.current && gridRef.current.api) {
				if (props.isClickBasedOnParent) {
				} else {
					gridRef.current.api.redrawRows();
					if (props.childGroupDefaultOpenOptions) {
						setTimeout(() => {
							expandChildGroup();
						}, 5);
					}
				}
			}
		}
	}, [tableInfo]);

	useEffect(() => {
		if (columnsInfo[`${props.apikey}`]) {
			setColumnDefs([
				...getColumnDefs(
					columnsInfo[`${props.apikey}`],
					props.isValueFormatterRequired,
				),
			]);
			if (gridRef && gridRef.current && gridRef.current.api) {
				gridRef.current.api.setColumnDefs(columnDefs);
				gridApi.sizeColumnsToFit();
			}
		}
	}, [columnsInfo]);

	useEffect(() => {
		if (props.columnDefs.length > 0) {
			setColumnDefs(
				getColumnDefs(props.columnDefs, props.isValueFormatterRequired),
			);
		}
		setColumnDefs(props.columnDefs);
	}, [props.columnDefs]);

	useEffect(() => {
		if (props.isOtherRequest) {
			let payload = {};
			fetchData(props.isOtherRequest).then((res) => {
				console.log("Response details", res);
				setRowData(res);
				payload[props.isOtherRequest] = [...res];
				dispatch({
					type: "TABLE_DATA",
					payload: payload,
				});
			});
		}
	}, [props.isOtherRequest]);

	const onFirstDataRendered = (params) => {
		setGridApi(params.api);

		gridColumnApi.setColumnsVisible(
			filterColumns,
			props.filterDefaultAllVisible,
		);

		//to load the default table style
		if (
			props.tableCssStyle === "default" ||
			props.tableCssStyle === undefined
		) {
			var allColumnIds = [];
			gridColumnApi.getAllColumns().forEach(function (column) {
				allColumnIds.push(column.colId);
			});
			gridColumnApi.autoSizeColumns(allColumnIds, "skipHeader");
		}

		if (props.tableCssStyle === "sizeColumnsToFit") {
			if (Object.keys(gridApi).length > 0) {
				gridApi.sizeColumnsToFit();
			}
		}
		if (props.tableCssStyle === "autoSizeAllColumns") {
			if (Object.keys(gridColumnApi).length > 0) {
				gridColumnApi.autoSizeAllColumns();
			}
		}
		if (props.isDefaultCheckBoxSelection) {
			params.api.forEachNode((node) =>
				node.setSelected(
					!!node.data &&
						props.isDefaultCheckBoxSelection.indexOf(
							node.data[`${props.defaultCheckBasedOn}`],
						) !== -1,
				),
			);
		}
	};

	// Export grid to excel
	useEffect(() => {
		if (isExportTable) {
			gridRef.current.api.exportDataAsExcel({
				onlySelected: selectedRowInfo[props?.apikey].length > 0,
			});
			dispatch({
				type: "EXPORT_TO_EXCEL",
				payload: false,
			});
		}
	}, [isExportTable]);

	// Delete grid rows
	useEffect(() => {
		if (deleteTableRow) {
			const selectedRowData = gridRef.current.api.getSelectedRows();
			gridRef.current.api.applyTransaction({ remove: selectedRowData });
			dispatch({
				type: "DELETE_SELECTED_TABLE_ROW",
				payload: false,
			});
		}
	}, [deleteTableRow]);

	useEffect(() => {
		let hiddenColumnsArray = filterColumns;
		if (
			dropdownSelectionData !== undefined &&
			dropdownSelectionData[filterColumnId] !== undefined &&
			gridColumnApi.setColumnsVisible !== undefined
		) {
			//column filter
			let columnsArray = [];
			dropdownSelectionData[filterColumnId]?.map((value) => {
				columnsArray.push(value.value);
			});
			gridColumnApi.setColumnsVisible(columnsArray, true);
			let array3 = hiddenColumnsArray?.filter(
				(val) => !columnsArray.includes(val),
			);
			gridColumnApi.setColumnsVisible(array3, false);
			//row filter
			if (props.rowFilter) {
				const rowArray = dropdownSelectionData[filterColumnId]?.map(
					({ value }) => value,
				);
				setRowData((prev) => {
					return tableValues[props.apikey]?.filter((row) =>
						rowArray.includes(row[filterColumn]),
					);
				});
			}
		}
	}, [dropdownSelectionData]);

	const isImagePresent = () => {
		const imageColumn = props.columnDefs.find((column) => {
			return column?.cellRenderer === "childMessageRenderer";
		});

		if (imageColumn) {
			return true;
		}

		return false;
	};

	const getRowStyle = (params) => {
		if (params.data && params.data.is_finalized) {
			return { background: theme.palette.bgTableFinalized };
		} else if (params.data && params.data.is_product_performance) {
			return {
				background:
					params.data.status === "track"
						? theme.palette.bgSuccessTransparent
						: params.data.status === "over"
						? theme.palette.bgWarningTransparent
						: theme.palette.bgDangerTransparent,
			};
		}

		return { background: theme.palette.textWhite };
	};
	const onGridReady = async (params) => {
		setGridApi(params.api);
		setGridColumnApi(params.columnApi);
	};

	const cellValueChanged = async (params) => {
		if (params.newValue !== params.oldValue) {
			const updatedData = [];
			updatedData.push({
				rowIndex: params.rowIndex,
				colId: params.column.colId,
			});
			setContext({
				// ...context,
				editedColumns: [...context.editedColumns].concat(updatedData),
			});

			if (props.isMultiplyApplyToRow) {
				let mult = 0;
				gridRef.current.api.forEachNode((node) => {
					if (params.rowIndex === node.rowIndex) {
					} else if (node.rowIndex === props.isMultiplyApplyToRow) {
						let updatedValue = Math.round(
							params.newValue * mult.replace(/\,/g, ""),
						);
						let paramsReq = {};
						node.setDataValue(
							params.colDef.field,
							NoFormatter({ value: updatedValue }),
						);

						if (props.updateToOtherTables) {
							// props.updateToOtherTables.forEach(tableObj=>{
							let tableObj = props.updateToOtherTables[0];
							const tableData = tableInfo[`${tableObj}`];
							// let colName = params.colDef.field.split("week_")
							//     // console.log("*******",node.data[`${params.colDef.field}`])
							//     // dataObj[`W ${colName[1]}`] = node.data[`${params.colDef.field}`]
							//     // dataObj.data[`${colName[1] -1 }`] = parseInt(Math.round(node.data[`${params.colDef.field}`].replace(/\,/g,'')))
							// tableData[3][`W ${colName[1]}`] = node.data[`${params.colDef.field}`]
							// tableData[3]['data'][`${colName[1] -1 }`] = parseInt(Math.round(node.data[`${params.colDef.field}`].replace(/\,/g,'')))
							tableData.filter((dataObj, index) => {
								if (dataObj["id"] == 4) {
									let colName = params.colDef.field.split("week_");
									dataObj[`W ${colName[1]}`] =
										node.data[`${params.colDef.field}`];
									dataObj.data[`${colName[1] - 1}`] = parseInt(
										Math.round(
											node.data[`${params.colDef.field}`].replace(/\,/g, ""),
										),
									);
								}
							});
							paramsReq[`${tableObj}`] = tableData;
							// })

							dispatch({
								type: "TABLE_DATA",
								payload: paramsReq,
							});
							// debugger;
						}
					} else {
						mult = node.data[`${params.colDef.field}`];
					}
				});
			}
			gridRef.current.api.refreshCells();
		}
	};

	const onCellClicked = async (params) => {
		params.data[props.clickChangeField] = props.clickChangeValue;
		gridRef.current.api.refreshCells();
	};

	const onSelectionChanged = (event) => {
		const selectedOptions = gridApi.getSelectedRows();
		console.log(selectedOptions);
		if (props.isStoreRequired) {
			let params = {};
			if (props.isOtherRequest) {
				params[`${props.isOtherRequest}`] = [...selectedOptions];
			} else {
				params[`${props.apikey}`] = [...selectedOptions];
			}
			dispatch({
				type: "SELECTED_ROWS",
				payload: params,
			});
		}
		if (props.onRowselectionChanged) {
			props.onRowselectionChanged(selectedOptions, gridApi);
		}
	};
	const sideBar = useMemo(() => {
		let params = {
			toolPanels: [
				{
					id: "columns",
					labelDefault: "Columns",
					labelKey: "columns",
					iconKey: "columns",
					toolPanel: "agColumnsToolPanel",
				},
				{
					id: "filters",
					labelDefault: "Filters",
					labelKey: "filters",
					iconKey: "filter",
					toolPanel: "agFiltersToolPanel",
				},
				{
					id: "show_hide_metrics",
					labelDefault: props.subRowHideOnAllRows
						? "Show/Hide Rows"
						: "Show/Hide Metrics",
					labelKey: "show_hide_metrics",
					iconKey: "menu",
					toolPanel: AddHideMetrics,
					toolPanelParams: {
						onChange: true,
						tableRef: gridRef,
						groupBy: props.groupBy,
						subGroupBy: props.subGroupBy,
						subRowHideOnAllRows: props.subRowHideOnAllRows,
					},
				},
			],
			//   defaultToolPanel: 'filters',
		};
		if (props.isToolPanelRequired) {
			params = {
				toolPanels: [
					{
						id: "columns",
						labelDefault: "Show/Hide metrics",
						labelKey: "columns",
						iconKey: "columns",
						toolPanel: "agColumnsToolPanel",
					},
					{
						id: "filters",
						labelDefault: "Filters",
						labelKey: "filters",
						iconKey: "filter",
						toolPanel: "agFiltersToolPanel",
					},
				],
			};
		}
		return params;
	}, []);

	function isExternalFilterPresent() {
		return true;
	}

	function doesExternalFilterPass(node) {
		return !node.data.hide;
	}

	const expandParentGroup = useCallback(() => {
		gridRef.current.api.forEachNode((node) => {
			if (node.level === 0) {
				node.setExpanded(true);
			}
		});
	}, []);

	const expandChildGroup = useCallback(() => {
		if (gridRef && gridRef.current && gridRef.current.api) {
			gridRef.current.api.forEachNode((node) => {
				if (props.childGroupDefaultOpenOptions.indexOf(node.key) !== -1) {
					node.parent.setExpanded(true); // ensure parent 'country' group is also expanded
					node.setExpanded(true);
				}
			});
		}
	}, []);

	const rowClassRules = useMemo(() => {
		return {
			// row style function
			"ia-forecast": (params) => {
				return (
					props.isColorCodingRequired &&
					params.data &&
					(params.data["ref"] === "WP" || params.data["ref"] === "WF")
				);
			},
			"ia-assort-carryover": (params) => {
				return (
					props.isColorCodingRequired &&
					params.data &&
					(params.data["id"] === "8" ||
						params.data["id"] === "9" ||
						params.data["choice_id"] === "Total")
				);
			},
		};
	});
	const aggFuncs = useMemo((params) => {
		return {
			mySum: (params) => {
				let sum = 0;
				params.values.forEach((value) => {
					return (sum +=
						typeof value === "number"
							? value
							: parseInt(value?.replace(",", "")));
				});
				return currencyFormatter({ value: sum });
			},
			myAvg: (params) => {
				let sum = 0;
				params.values.forEach((value) => {
					return (sum +=
						typeof value === "number"
							? value
							: parseInt(value?.replace(",", "")));
				});
				return currencyFormatter({ value: sum / params.values.length });
			},
			myAvgNormal: (params) => {
				let sum = 0;
				params.values.forEach((value) => {
					return (sum +=
						typeof value === "number"
							? value
							: parseInt(value?.replace(",", "")));
				});
				return NoFormatter({ value: sum / params.values.length });
			},
			myAvgPercent: (params) => {
				let sum = 0;
				params.values.forEach((value) => {
					return (sum +=
						typeof value === "number"
							? value
							: parseInt(value?.replace(",", "")));
				});
				return percentageFormatter({ value: sum / params.values.length });
			},
		};
	}, []);

	return (
		<div
			id="myGrid"
			style={{
				height: props.height || "300px",
				width: "100%",
			}}
			className={
				isImagePresent()
					? "ag-theme-custom-react-image impact-table-container-style"
					: "ag-theme-custom-react impact-table-container-style"
			}
		>
			<AgGridReact
				ref={gridRef}
				{...props}
				onGridReady={onGridReady}
				rowSelection={props.rowSelection}
				suppressRowClickSelection={true}
				columnDefs={columnDefs}
				defaultColDef={{ ...defaultColDef }}
				groupSelectsChildren={true}
				pagination={props.pagination}
				paginateChildRows={true}
				autoGroupColumnDef={props.autoGroupColumnDef}
				rowData={rowData}
				headerHeight={headerHeight || 37}
				rowHeight={rowHeight || null}
				masterDetail={true}
				aggFuncs={aggFuncs}
				suppressAggFuncInHeader={true}
				onFirstDataRendered={onFirstDataRendered}
				floatingFilter={props.floatingFilter}
				cacheQuickFilter={true}
				suppressMenuHide={true}
				getRowStyle={getRowStyle}
				frameworkComponents={{
					CustomRenderComponent: CustomRendererComponent,
					EditWeekData: EditWeekData,
					EditTextField: EditTextField,
					CellTextField: CellTextField,
					CellImageField: CellImageField,
					CellPopOverImageField: CellPopOverImageField,
					DropdownCellRenderer: DropdownCellRenderer,
					CellSwitchField: CellSwitchField,
					PreformattedText: PreformattedText,
					CellAddIcon: CellAddIcon,
					CellMultiSelectDropdown: CellMultiSelectDropdown,
					CellChips: CellChips,
					CellColor: CellColor,
				}}
				onCellValueChanged={cellValueChanged}
				onCellClicked={onCellClicked}
				context={context}
				onSelectionChanged={onSelectionChanged}
				isExternalFilterPresent={isExternalFilterPresent}
				doesExternalFilterPass={doesExternalFilterPass}
				{...gridOptions}
				rowClassRules={rowClassRules}
			/>
		</div>
	);
};

export default Table;
