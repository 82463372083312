import React from "react";
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
function CellChips(props) {
	let val = props.value || "";
	let varInArr = val.split(",");
    const colorMap = {
        red: "error",
        green: "success",
        orange: "warning",
        blue: "info",
        darkblue:"primary",
        purple:"secondary"
      };
	return (
		
        <div>
      {props.value.length > 0 && (
        <Stack direction="row" spacing={1}>
          {varInArr.map((el, index) => {
            if (index % 2 === 0) {
              return (
                <Chip
                  key={index}
                  label={el}
                  variant="outlined"
                  color={colorMap[varInArr[index + 1]] || "default"}
                />
              );
            } else {
              return null;
            }
          })}
        </Stack>
      )}
    </div>
    
	);
}
export default CellChips;
