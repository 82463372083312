import React from 'react';
import PropTypes from 'prop-types';
import ToggleButton from '@mui/material/ToggleButton';
import { StyledEngineProvider } from '@mui/material/styles';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import styles from './index.module.css';

function MUIButtonGroup(props) {
  const {
    value,
    onChange,
    options,
    uncheckingRequired,
    size,
    iconColor,
    width,
    backgroundColor,
    disabled,
    ...rest
  } = props;

  return (
    <StyledEngineProvider injectFirst>
      <ToggleButtonGroup
        size={size || 'small'}
        color="primary"
        value={value}
        exclusive
        onChange={(event, newValue) => {
          if (newValue || uncheckingRequired) onChange(newValue);
        }}
        {...rest}
        style={{ width: `${width}%`, backgroundColor: backgroundColor }}
        disabled={disabled}
      >
        {options.map((e, idx) => (
          <ToggleButton
            value={e.value}
            id={`radio-${idx}`}
            style={{ color: iconColor, width: width && `${100 / options.length}%` }}
          >
            {e.icon && (
              <span style={{ marginRight: `${e.label ? '5px' : 'none'}` }}>
                <i className={`fas fa-${e.icon}`} />
              </span>
            )}
            {e.label || null}
          </ToggleButton>
        ))}
      </ToggleButtonGroup>
    </StyledEngineProvider>
  );
}

MUIButtonGroup.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  options: PropTypes.arrayOf([
    PropTypes.shape({ value: PropTypes.string, label: PropTypes.string, icon: PropTypes.string }),
  ]),
  uncheckingRequired: PropTypes.bool,
  size: PropTypes.string,
  disabled: PropTypes.bool,
};

MUIButtonGroup.defaultProps = {
  value: undefined,
  onChange: () => {},
  options: [],
  uncheckingRequired: false,
  size: '',
  disabled: false,
};

export default MUIButtonGroup;
