import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import TextFieldWithLabel from "../../../components/TextField/TextFieldWithLabel";
import { Grid, InputAdornment, TextField } from "@mui/material";
import LockIcon from "@mui/icons-material/Lock";
import { makeStyles } from "@mui/styles";
import LockOpen from "@mui/icons-material/LockOpen";
import LockOpenIcon from '@mui/icons-material/LockOpen';
const styles = (theme) => ({
  inputLabel: {
    lineHeight: "1.5",
    color: "#8596a9",
    fontSize: "0.9rem",
    marginBottom: "0.2rem",
  },
  disabled: {
    backgroundColor: "#C7E4FD !important",
    color: "#C7E4FD !important",
  },
  refreshView: {
    backgroundColor: "#f07c7c !important",
  },
});
const useStyles = makeStyles(styles);

export const EditTextField = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [updatedValue, setUpdatedValue] = useState({});
  const { selectedRowInfo, tableInfo, editActionInfo, dependentInfo } =
    useSelector((state) => state.home);
  const handleInputChange = (e) => {
    const { label, value } = e.target;
    const updateObj = {
      currentRow: props.data,
      field: props.colDef.field,
      value: value,
      rowData: props.rowData,
      rowIndex: parseInt(props.data.id) - 1,
    };
    setUpdatedValue(updateObj);
  };
  const handleOnBlur = () => {
    const { currentRow } = updatedValue;
    let dependentParams = {};
    if (Object.keys(updatedValue).length > 0) {
      if (currentRow["ref"] === "Placeholder1") {
        if (dependentInfo[`placeholder_dependent`]) {
          let placeholderParams = {};
          placeholderParams[`tb_plan_enable_pivot`] =
            tableInfo[`tb_plan_enable_pivot_placeholder_data`];
          dispatch({
            type: "TABLE_DATA",
            payload: placeholderParams,
          });
        } else {
          dependentParams[`placeholder_dependent`] = true;
          dispatch({
            type: "DEPENDENT_COMPONENTS",
            payload: dependentParams,
          });
        }
      }

      if (currentRow[`ref`] === "Placeholder2") {
        if (dependentInfo[`placeholder_dependent`]) {
          let placeholderParams = {};
          placeholderParams[`tb_plan_enable_pivot`] =
            tableInfo[`tb_plan_enable_pivot_placeholder_data`];
          dispatch({
            type: "TABLE_DATA",
            payload: placeholderParams,
          });
        } else {
          dependentParams[`placeholder_dependent`] = true;
          dispatch({
            type: "DEPENDENT_COMPONENTS",
            payload: dependentParams,
          });
        }
      }
    }
  };

  const handleLockOption = (rowData) => {
    let params = {};
    // const metricData = tableInfo["tb_plan_department_locked_celldata"]
    let metricData = tableInfo[`${props.action.parent_table_key}`];
    switch (props.action.type) {
      case "ROW_LOCKING":
        metricData = metricData.filter((obj) => {
          if (obj.ref === rowData.ref) {
            obj[`${props.colDef.field}_locked`] = obj[
              `${props.colDef.field}_locked`
            ]
              ? false
              : true;
            obj[`${rowData.id}_locked`] = obj[`${rowData.id}_locked`]
              ? false
              : true;
            return obj;
          }
          return obj;
        });
        params[`${props.action.parent_table_key}`] = metricData;
        console.log("Row Locking", params);
        dispatch({
          type: "TABLE_DATA",
          payload: params,
        });
        break;

      default:
        break;
    }
  };
  return (
    <>
      {props.data ? (
        <>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <div style={{ display: "flex", alignItems: "center" }}>
                <TextFieldWithLabel
                  type="text"
                  onChange={handleInputChange}
                  defaultValue={props.value}
                  handleOnBlur={handleOnBlur}
                  disabled={props.disabled}
                  startAdornment={
                    props.data.position === "start" && (
                      <InputAdornment position="start">
                        {props.data.symbol}
                      </InputAdornment>
                    )
                  }
                  endAdornment={
                    props.data.position === "end" ? (
                      <>
                        <InputAdornment position="end">
                          {props.data.symbol}
                          {props.isLockRequired ? (
                            <>
                              { props.data[`${props.colDef.field}_locked`] ? (<LockIcon
                                sx={{ fontSize: 15, cursor: "pointer" }}
                                onClick={() => {
                                  handleLockOption(props.data);
                                }}
                              />) : (
                                <>
                                  <LockOpenIcon
                                sx={{ fontSize: 15, cursor: "pointer" }}
                                onClick={() => {
                                  handleLockOption(props.data);
                                }}
                              />
                                </>
                              )}
                            </>
                          ) : null}
                        </InputAdornment>
                      </>
                    ) : (
                      <>
                        {props.isLockRequired ? (
                          <>
                            { props.data[`${props.colDef.field}_locked`] ? (<LockIcon
                                sx={{ fontSize: 15, cursor: "pointer" }}
                                onClick={() => {
                                  handleLockOption(props.data);
                                }}
                              />) : (
                                <>
                                  <LockOpenIcon
                                sx={{ fontSize: 15, cursor: "pointer" }}
                                onClick={() => {
                                  handleLockOption(props.data);
                                }}
                              />
                                </>
                              )}
                          </>
                        ) : null}
                      </>
                    )
                  }
                  disabled={
                    props.data[`${props.colDef.field}_locked`] ||
                    props.data[`${props.data.id}_locked`]
                  }
                  className={
                    props.data[`${props.colDef.field}_locked`] ||
                    props.data[`${props.data.id}_locked`]
                      ? classes.disabled
                      : ( (["Ulta Beauty","Locks & Mane"].indexOf(props.data['ref']) !== -1 && props.colDef.field === "feb_total" && props.data.hasOwnProperty("is_colour") ) ? (classes.refreshView) : null )
                  }
                />
              </div>
            </Grid>
          </Grid>
        </>
      ) : (
        <>
          {/* {props.data && props.data[`${props.mappingKey}`]} {props.data.symbol} */}
        </>
      )}
    </>
  );
};
