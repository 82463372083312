import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FormLabel, Grid, IconButton, Paper } from "@mui/material";
import { makeStyles } from "@mui/styles";
import Typography from "@mui/material/Typography";
import { fetchFormDataAction } from "../action";
import CustomCard from "../../../components/CustomCard";
import HorizontalTables from "../CustomComponents/horizontalTables";
import VerticleTables from "../CustomComponents/verticleTables";
import HorizontalCharts from "../CustomComponents/horizontalCharts";
import VerticalCharts from "../CustomComponents/verticalCharts";
import CustomisedCard from "../CustomComponents/customisedCard";
import Steppers from "../../../components/Steppers/Steppers";
import Tabs from "./TabResolver";
import Map from "../../../components/Map";
import InputBase from "@mui/material/InputBase";
import TextFieldWithLabel from "../../../components/TextField/TextFieldWithLabel";
import CustomActionButton from "../CustomComponents/CustomActionButton";
import FiltersList from "../FiltersList";
import Calendar from "../../../components/Calendar";
import KpiCardComponent from "../CustomComponents/KpiCardComponent";
import FormsList from "../DynamicForms/filtersForms";
import SelectRenderer from "../../../components/Select/SelectRenderer";
import { CustomModal } from "../CustomComponents/customModal";
import ActionComponents from "../CustomComponents/actionComponents";
import { StrategyConfig } from "../strategyConfig";
import { OptimizationConstraints } from "../optimizationConstraints";
import ButtonDropdown from "../CustomComponents/ButtonDropdown";
import CustomRadioButtons from "../CustomComponents/CustomRadioButtons";
import { Box } from "@mui/system";
import SearchIcon from "@mui/icons-material/Search";
import CustomTable from "../CustomComponents/CustomTable";
import InfoPanel from "../../../components/InfoPanel";
import StepFourImg from "../../../assets/images/Img.png";
import SplitTable from "../../Home/SplitTable";
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";
import CustomDateRange from "../CustomComponents/CustomDateRange";
import DownloadFiles from "../CustomComponents/DownloadFiles";
import CheckboxGroup from "../../../components/CheckboxGroup";
import TextArea from "../../../components/TextArea";
import CustomAccordion from "../../../pages/Home/CustomComponents/CustomAccordian";
import VerticalTabs from "../CustomComponents/VerticalTabs";

import "../../MainCss.scss";
import CircularProgressBar from "../CustomComponents/CircularProgressBar";
import DataLabel from "../CustomComponents/DataLabel";
import ReportsMap from "../../../components/ReportsMap";

const styles = (theme) => ({
  headerCard: {
    ...theme.content.card,
    width: "100%",
    height: "100%",
    overflow: theme.content.overflow.visible,
  },
  container: {
    ...theme.content.flexStyles.flexRow,
    ...theme.content.flexStyles.flexAlignBetweenCenter,
  },
});

const useStyles = makeStyles(styles);

const ComponentResolver = (props) => {
  const { pageObject } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const [loader, setLoader] = useState(false);
  const [selectedFilters, setSelectedFilters] = useState({});
  const { apiData, dependentInfo, tableInfo, selectedRowInfo } = useSelector(
    (state) => state.home
  );
  const { formMasterValuesData, formMenuData } = useSelector(
    (state) => state.filter
  );

  const [open, setOpen] = useState(false);
  const [componentsList, setComponentsList] = useState([]);
  const [selectedOption, setSelectedOption] = useState({});

  useEffect(() => {
    if (formMasterValuesData.length === 0) {
      dispatch(
        fetchFormDataAction({
          model_names: ["tb_form_master", "tb_form_master_values"],
        })
      );
    }
  }, []);

  useEffect(() => {
    if (pageObject["enable_default_key"]) {
      let params = {};
      params[`default-key`] = true;
      dispatch({
        type: "DEPENDENT_COMPONENTS",
        payload: params,
      });
    }
    if (pageObject["components"]) {
      setComponentsList([...pageObject.components]);
    }
  }, [pageObject]);

  const reloadFilters = async () => {
    setSelectedFilters({});
  };

  const openLoader = () => {
    setLoader(true);
  };

  const closeLoader = (metric) => {
    setLoader(false);
  };
  const updateState = (params) => {
    setSelectedFilters(params);
  };
  const appliedFiltersAction = (params, data) => {
    if (data.action && data.action.type === "UPDATE_MASTER_PLAN") {
      let tableParams = {};
      tableParams[`${data.action.source}`] = tableInfo[`${data.action.key}`];
      if (selectedFilters.hasOwnProperty("Plan Version")) {
        const planData = selectedFilters["Plan Version"][0]["label"];
        if (planData === "Working Plan") {
          let reqParams = {};
          reqParams[`${data.filter_id}`] = true;
          dispatch({
            type: "DEPENDENT_COMPONENTS",
            payload: reqParams,
          });
          dispatch({
            type: "TABLE_DATA",
            payload: tableParams,
          });
        } else {
          tableParams[`${data.action.source}`] =
            tableInfo[`${data.action.originalPlan}`];
          dispatch({
            type: "TABLE_DATA",
            payload: tableParams,
          });
        }
      }
    }
  };

  const onReset = () => {
    reloadFilters();
  };

  const onLabelActionClick = (data) => {
    console.log("========data", data);
    switch (data.action_type) {
      case "modal":
        setOpen(!open);

      case "click":
        let params = {};
        params[`${data.name}`] = dependentInfo[`${data.name}`]
          ? !dependentInfo[`${data.name}`]
          : true;
        dispatch({
          type: "DEPENDENT_COMPONENTS",
          payload: params,
        });
      case "RECALCULATE_IA_FORECAST":

      // setModalInfo(data)
      default:
        break;
    }
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleSelect = (params, obj) => {
    switch (obj.actionType) {
      case "AddNewRow":
        const insertedData = [];
        const isDataExisted = tableInfo[`${obj.parent_table_key}`].map(
          (tablObj) => tablObj[`${obj.unique_key}`]
        );
        if (!isDataExisted.includes(params.selectedItems[0]["value"])) {
          tableInfo[`${obj.parent_table_key}`].filter((tableObj, index) => {
            if (index === 0) {
              let reqParams = {};
              Object.keys(tableObj).forEach((nestedObj) => {
                reqParams[`${nestedObj}`] = 0;
              });
              reqParams[`${obj.unique_key}`] = params.selectedItems[0]["value"];
              insertedData.push({ ...reqParams });
            }
          });
          let requestData = {};
          requestData[`${obj.parent_table_key}`] = [
            ...tableInfo[`${obj.parent_table_key}`],
            ...insertedData,
          ];
          dispatch({
            type: "TABLE_DATA",
            payload: requestData,
          });
        }

        break;

      default:
        break;
    }
  };
  const onButtonClick = (data) => [console.log("Clicked Action", data)];

  const handleCheckboxChange = (e, obj, index) => {
    const { name, value } = e.target;
    if (obj["isStoreRequired"]) {
      let params = {};
      params[`${name}`] = dependentInfo[`${name}`]
        ? !dependentInfo[`${name}`]
        : true;
      dispatch({
        type: "DEPENDENT_COMPONENTS",
        payload: params,
      });
    } else {
      setSelectedOption({
        index: index,
        dependentOn: name,
      });
    }
  };

  const renderView = (propsObj) => {
    const { data, type, key } = propsObj;
    switch (type) {
      case "stepper":
        return <Steppers data={data} />;
      case "textField":
        return (
          <>
            {data.info.map((field) => (
              <TextFieldWithLabel
                type={field.type}
                label={field.label}
                width={field.width}
                defaultValue={field.defaultValue}
              />
            ))}
          </>
        );
      case "map":
        return <Map info={data} />;
      case "search":
        return (
          <Box sx={{ display: { xs: "none", md: "block" } }}>
            <Paper
              component="form"
              sx={{
                p: 0,
                display: "flex",
                alignItems: "center",
                width: "100%",
              }}
            >
              <InputBase
                sx={{ ml: 1, flex: 1, padding: 0 }}
                placeholder={`Search`}
                inputProps={`search`}
                onChange={(e) => () => {}}
              />
              <IconButton type="submit" sx={{ p: "10px" }} aria-label="search">
                <SearchIcon />
              </IconButton>
            </Paper>
          </Box>
        );
      case "date-range-picker":
        return <CustomDateRange data={data} />;
      case "radio":
        return <CustomRadioButtons data={data} />;

      case "KPICard":
        return <KpiCardComponent data={data} />;
      case "card":
        return (
          <Typography gutterBottom variant="h5">
            {data?.title}
            {data.info?.map((item) => (
              <CustomisedCard item={item} />
            ))}
          </Typography>
        );
      case "horizontalCharts":
        return <HorizontalCharts charts={data.info} {...data} />;
      case "verticalCharts":
        return <VerticalCharts charts={data.info} />;
      case "horizontalTables":
        return <HorizontalTables tables={data.info} />;
      case "table":
        return (
          <VerticleTables
            tables={data.info}
            createExtraColDefs={data.createExtraColDefs}
          />
        );
      case "actionButton":
      case "bottomActionButton":
        return <CustomActionButton data={data} />;
      case "buttonDropdown":
        return <ButtonDropdown data={data} />;
      case "tabs":
        return (
          <Tabs
            pageObject={{
              tabs: data.info,
              noReset: data.noReset,
              components: data.components,
              redirectionOnClick: data.redirectionOnClick,
              initialSelectedIndex: data.initialSelectedIndex,
              ...data,
            }}
          />
        );
      case "calendar":
        return <Calendar onCalEventClick={null} dataObj={data} />;
      case "hr":
        return <hr />;
      case "filters":
        return (
          <div className={"impact-filterList impact-filterList" + key + type}>
            <FiltersList
              hideButtons={data.hide_buttons}
              expand={data.expand}
              openLoader={openLoader}
              closeLoader={closeLoader}
              updateParentState={updateState}
              appliedFilters={(params) => {
                appliedFiltersAction(params, data);
              }}
              onReset={(params) => {
                onReset(params, data);
              }}
              screenName={"Filters"}
              isFilterNotRequired={{}}
              filter_id={data.filter_id}
              filter_label={data.filter_label}
              isFilterButtonNotRequired={data.isFilterButtonNotRequired}
              horizontalView={data.horizontalView}
              {...data}
            />
          </div>
        );
      case "forms":
        return (
          <>
            <FormsList
              updateParentState={updateState}
              appliedFilters={appliedFiltersAction}
              onReset={onReset}
              isSubmitRequired={data.isSubmitRequired}
              form_label={data.form_label}
              isTopFilters={data.isTopFilters}
              filter_id={data.filter_id}
              onLabelActionClick={() => {
                onLabelActionClick(data);
              }}
              onButtonClick={() => {
                onButtonClick(data);
              }}
              submitButtonName={data.submitButtonName}
              resetName={data.resetName}
              horizontalView={data.horizontalView}
            />
            {open && data.index === key ? (
              <>
                <CustomModal show={open} {...data} handleClose={handleClose} />
              </>
            ) : null}
          </>
        );
      case "select":
        const optionsList = formMasterValuesData.filter((obj) => {
          if (parseInt(obj.parent_id) === data.filter_id) {
            obj.label = obj.name;
            obj.value = obj.name;
            return obj;
          }
        });

        const formDetails =
          formMenuData.filter(
            (obj) => parseInt(obj["categoryid"]) === data.filter_id
          )[0] || {};
        const defaultValue = formMasterValuesData.filter((obj) => {
          if (parseInt(obj.parent_id) === data.filter_id) {
            if (obj.is_default_value === "Y") {
              obj.label = obj.name;
              obj.value = obj.name;
              return obj;
            }
          }
        });
        return (
          <>
            <Grid item md={data.grid || 3}>
              <SelectRenderer
                options={optionsList}
                isMandatory={data.isMandatory}
                isMulti={
                  data.isFormFilters
                    ? formDetails.type === "single-select"
                      ? false
                      : true
                    : false
                }
                width="18rem"
                filterLabel={
                  data.isFormFilters ? formDetails.label : data.form_label
                }
                updateSelected={(params) => {
                  handleSelect(params, data);
                }}
                selectedItems={defaultValue}
                {...data}
              />
            </Grid>
          </>
        );
      case "spacer":
        return (
          <>
            <div className="content-spacer" style={data.style}></div>
          </>
        );
      case "modal":
        return <CustomModal show={open} data={data} />;
      case "actionComponents":
        return (
          <>
            <ActionComponents data={data} />
          </>
        );
      case "label":
        return (
          <div style={data.style}>
            <label>{data.name} </label>
          </div>
        );
      case "strategry-component":
        return (
          <>
            <StrategyConfig />
          </>
        );
      case "optimization-constraints":
        return (
          <>
            <OptimizationConstraints />
          </>
        );
      case "horizontal-labels":
        return (
          <>
            <Grid container>
              <Grid item xs={3} sm={3} md={3}>
                {data.label ? (
                  <FormLabel id="demo-row-radio-buttons-group-label">
                    {data.label}
                  </FormLabel>
                ) : null}
              </Grid>
              {data.options?.map((option) => (
                <Grid item xs>
                  <FormLabel id="demo-row-radio-buttons-group-label">
                    {option.label}
                  </FormLabel>{" "}
                  &nbsp;&nbsp;
                  <FormLabel style={{ color: "black" }}>
                    {selectedRowInfo[`${data.parent_table_key}`] &&
                      selectedRowInfo[`${data.parent_table_key}`][0][
                        `${option.mappingKey}`
                      ]}
                  </FormLabel>
                </Grid>
              ))}
            </Grid>
          </>
        );
      case "image":
        return (
          <div style={{ display: "flex", justifyContent: "space-around" }}>
            <img width="100%" src={StepFourImg} alt="photo" />
          </div>
        );
      case "info":
        return <InfoPanel data={data.info} />;
      case "split-table":
        return <SplitTable />;

      case "toggle":
        return (
          <FormControlLabel
            control={<Switch defaultChecked />}
            label={data.label}
          />
        );
      case "download":
        return <DownloadFiles {...data} />;
      case "Checkboxes":
        return (
          <>
            <CheckboxGroup
              label={data.label}
              options={data.options}
              row={!data.column}
              values={1}
              handleChange={(e) => handleCheckboxChange(e, data, 0)}
            />
          </>
        );
      case "customTable":
        return <CustomTable />;
      case "textarea":
        return <TextArea data={data} />;
      case "accordian":
        return <CustomAccordion data={data} />;
      case "verticalTabs":
        return <VerticalTabs data={data} />;
      case "circularProgressBar":
        return <CircularProgressBar data={data} />;
      case "dataLabel":
        return <DataLabel data={data} />;
      case "reportsMap":
        return <ReportsMap info={data} />;
      default:
        return "";
    }
  };

  return (
    <CustomCard cardStyles={classes.headerCard}>
      {Object.keys(pageObject).length > 0 && pageObject["pageTitle"] ? (
        <>
          <h3>{pageObject.pageTitle}</h3>
        </>
      ) : null}
      {pageObject.components &&
        componentsList.map((item, index) => (
          <div
            key={item.type}
            className={
              "impact-container-resolver  impact-subContainer-resolver" +
              item.type
            }
          >
            {item.hasOwnProperty("flexComponent") && (
              <div
                style={{
                  display: "flex",
                  justifyContent: item.flexComponent.flex,
                }}
                className={"impact-flexComponent-" + item.type}
              >
                {item.flexComponent.components.map((thisComp, index) => {
                  return (
                    <>
                      <div>
                        {renderView({
                          key: index,
                          type: thisComp.type,
                          data: thisComp,
                        })}
                      </div>
                      <div style={{ marginRight: "1rem" }} />
                    </>
                  );
                })}
              </div>
            )}
            {item["dependentOn"] ? (
              <>
                {dependentInfo[`${item.dependentOn}`] ? (
                  <>
                    {item["rootDependentName"] ? (
                      <>
                        {dependentInfo[`${item.rootDependentName}`] ? (
                          <>
                            {renderView({
                              key: index,
                              type: item.type,
                              data: item,
                            })}
                          </>
                        ) : null}
                      </>
                    ) : (
                      <>
                        {renderView({
                          key: index,
                          type: item.type,
                          data: item,
                        })}
                      </>
                    )}
                  </>
                ) : null}
              </>
            ) : (
              <>
                {renderView({
                  key: index,
                  type: item.type,
                  data: item,
                })}
              </>
            )}

            {/* <div className="content-spacer"></div> */}
          </div>
        ))}
    </CustomCard>
  );
};

export default ComponentResolver;
