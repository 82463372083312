import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { Chip } from '@mui/material';

const useStyles = makeStyles({
  summaryContainer: {
    border: `1px solid lightgray`,
    margin: `1rem 0`,
    padding: `0.5rem`,
    borderRadius: 10,
    fontSize: '12px',
    backgroundColor: 'white',
  },
  summaryChip: {
    margin: '0 0.1rem',
  },
  dimension: {
    margin: '0px 20px 0px 10px',
  },
});
const FiltersSummary = ({ filtersSummary }) => {
  const classes = useStyles();

  const handleDelete = (item) => {
    console.info('You clicked the delete icon.', item);
  };

  const getChips = (dimension) => {
    if (filtersSummary[dimension].label) {
      return (
        <span className={classes.dimension}>
          {filtersSummary[dimension].label}
          {filtersSummary[dimension].section.map((item) => (
            <Chip
              className={classes.summaryChip}
              label={item}
              onDelete={() => handleDelete(item)}
            />
          ))}
        </span>
      );
    }
  };

  return (
    <div className={classes.summaryContainer}>
      {Object.keys(filtersSummary).map((dimension) => getChips(dimension))}
    </div>
  );
};

export default FiltersSummary;
