import React, { Component } from "react";
import moment from "moment";
import { fetchHierarchyData } from "./api";
import SelectRenderer from "../../../components/Select/SelectRenderer";
import { Grid } from "@mui/material";
import CustomButton from "../../../components/CustomButton";
import FilterAltOutlined from "@mui/icons-material/FilterAltOutlined";
import Refresh from "@mui/icons-material/Refresh";
import "@trendmicro/react-tooltip/dist/react-tooltip.css";
import { Infotip } from "@trendmicro/react-tooltip";
import InfoIcon from "@mui/icons-material/Info";
import CustomDatePicker from "../../../components/CustomDatePicker";
import CustomDateRangePicker from "../../../components/CustomDateRangePicker";
import CheckboxGroup from "../../../components/CheckboxGroup";
import DropdownTreeSelectRenderer from "../../../components/dropdownTreeSelect";

export default class DynamicFilters extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...this.props.options,
      isLabelActionClicked: false,
    };
  }

  async componentDidMount() {
    const promises = [];
    if (this.props.hierarchyApiCalls.length > 0) {
      this.props.hierarchyApiCalls.forEach((obj) => {
        let promise;
        // if (obj.UrlName != '') {
        promise = fetchHierarchyData(obj.UrlName, {
          screenName: this.props.screenName,
          data: this.props.filterMasterValues,
          type: obj.type,
        });
        // }
        promises.push(promise);
      });
    }
    this.props.openLoader();
    try {
      const res = await Promise.all(promises);
      let optionsStateObj = {};
      this.props.hierarchyApiCalls.forEach((obj, index) => {
        this.props.closeLoader(obj);
        const data = res[index];
        if (obj["type"] === "dropdown-tree-select") {
          optionsStateObj[`${obj.optionsMappingKey}Options`] = data;
        } else {
          optionsStateObj[`${obj.optionsMappingKey}Options`] = data.map(
            (val) => ({
              value: val.value,
              label: val.label,
            })
          );
        }
      });
      this.setState({
        ...optionsStateObj,
      });

      if (this.props.accessChildState) {
        this.props.accessChildState(this.state);
      }
    } catch (error) {
      this.props.closeLoader(error);
    }
  }

  getFiltersData = async (type, currentStateObj, isDependentOn) => {
    const dependentFilters = this.props.filtersList
      .filter((obj) => obj.is_dependent_on)
      .map((obj) => obj.label);
    if (currentStateObj.length > 0) {
      try {
        let dependentObj = isDependentOn[0];
        console.log("dependent Obj", dependentObj);
        let reqObj = {
          // ...dependentObj,
          filter_values: currentStateObj.map((obj) => obj.value),
        };
        let parentObj = {};
        let selectedValue = {};
        parentObj[`${dependentObj.label}Options`] = [];
        // selectedValue[`${dependentObj.label}`] = [];
        this.props.openLoader();
        if (type === "Season") {
          selectedValue[`Planning Period`] = [
            new Date(moment("2023/01/29")),
            new Date(moment("2024/02/03")),
          ];
        } else {
          const data = await fetchHierarchyData(dependentObj.label, {
            screenName: this.props.screenName,
            ...reqObj,
          });
          parentObj[`${dependentObj.label}Options`] = data;
        }

        this.setState(parentObj);
        this.updateState(selectedValue);
        this.props.closeLoader(type);
      } catch (error) {
        this.props.closeLoader(type);
      }
    } else {
      let dependentObj = isDependentOn[0];
      let startIndex = dependentFilters.indexOf(dependentObj.label);
      const postFilters = dependentFilters.splice(
        startIndex,
        dependentFilters.length
      );
      let parentObj = {};
      let selectedValue = {};
      postFilters.forEach(async (obj) => {
        parentObj[`${obj}Options`] = [];
        selectedValue[`${obj}`] = [];
      });
      this.setState({
        ...parentObj,
      });

      this.updateState({
        ...selectedValue,
      });
    }
  };
  updateState = (params,options) => {
    console.log(params,options);
    this.props.updateParentState({
      ...params,
    },options);
  };

  reset() {
    let resetObj = Object.assign({}, this.props.options);
    this.props.hierarchyApiCalls.forEach((obj) => {
      delete resetObj[`${obj.optionsMappingKey}Options`];
    });

    this.setState({
      ...resetObj,
    });
    this.props.onReset();
  }

  handleCheckboxChange = (e, info, filterObj) => {
    const { name, value } = e.target;
    const isExistedData = filterObj.state.filter((obj) => obj.label === name);
    const notExistedData = filterObj.state.filter((obj) => obj.label !== name);
    const isNewData = info.filter((obj) => obj.label === name);
    let params = {};
    if (isExistedData.length > 0) {
      params[`${filterObj.label}`] = notExistedData;
    } else {
      params[`${filterObj.label}`] = filterObj.state.concat(isNewData);
    }
    this.updateState(params);
  };
  handleDateRangeChange = (values, filterObj) => {
    let params = {};
    params[`${filterObj.label}`] = values;
    this.updateState(params);
  };
  handleDateChange = (values, filterObj) => {
    let params = {};
    params[`${filterObj.label}`] = values;
    this.updateState(params);
  };
  getFilterLabel = (obj) => {
    return (
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div>
          {obj.label}
          {obj.required ? <span style={{ color: "red" }}> * </span> : null}
        </div>
        {/* <Infotip content={obj.infotip}>
					<InfoIcon color="primary" />
				</Infotip> */}
      </div>
    );
  };

  onChange = (currentNode, selectedNodes) => {
    console.log("onChange::", currentNode, selectedNodes);
  };
  onAction = (node, action) => {
    console.log("onAction::", action, node);
  };
  onNodeToggle = (currentNode) => {
    console.log("onNodeToggle::", currentNode);
  };

  renderFilters(obj) {
    switch (obj.type) {
      case "dropdown-tree-select":
        return (
          <DropdownTreeSelectRenderer
            data={this.state[`${obj.mappingKey}Options`]}
            filterLabel={obj.label}
            placeholder={obj.placeholder}
            isMandatory={obj.required}
            // onChange={this.onChange} onAction={this.onAction} onNodeToggle={this.onNodeToggle}
          />
        );
      case "multi-select":
        return (
          <>
            {/* {JSON.stringify(this.state[`${obj.mappingKey}Options`])} */}
            <SelectRenderer
              options={this.state[`${obj.mappingKey}Options`]}
              selectedItems={obj.state}
              isMandatory={obj.infotipEnabled ? false : obj.required}
              isMulti={true}
              placeholder={obj.placeholder}
              filterLabel={
                obj.infotipEnabled ? this.getFilterLabel(obj) : obj.label
              }
              updateSelected={(params) => {
                let updateData = {};
                updateData[`${obj.mappingKey}`] = params.selectedItems;
                this.updateState(updateData,this.state[`${obj.mappingKey}Options`]);
                if (obj.isDependentOn.length > 0) {
                  this.getFiltersData(
                    obj.mappingKey,
                    params.selectedItems,
                    obj.isDependentOn
                  );
                }
              }}
            />
          </>
        );
      case "single-select":
        return (
          <>
            <SelectRenderer
              options={this.state[`${obj.mappingKey}Options`]}
              selectedItems={obj.state}
              isMandatory={obj.infotipEnabled ? false : obj.required}
              isMulti={false}
              filterLabel={
                obj.infotipEnabled ? this.getFilterLabel(obj) : obj.label
              }
              placeholder={obj.placeholder}
              updateSelected={(params) => {
                let updateData = {};
                updateData[`${obj.mappingKey}`] = params.selectedItems;
                this.updateState(updateData,this.state[`${obj.mappingKey}Options`]);
                if (obj.isDependentOn.length > 0) {
                  this.getFiltersData(
                    obj.mappingKey,
                    params.selectedItems,
                    obj.isDependentOn
                  );
                }
              }}
            />
          </>
        );
        break;
      case "date":
        return (
          <>
            <CustomDatePicker
              label={obj.label}
              labeldirection={"column"}
              values={obj.state}
              isMandatory={obj.is_required}
              onChange={(value) => this.handleDateChange(value, obj)}
            />
          </>
        );
        break;
      case "date-range":
        return (
          <>
            <CustomDateRangePicker
              label={obj.label}
              labeldirection={"column"}
              values={obj.state}
              isMandatory={false}
              onChange={(values) => this.handleDateRangeChange(values, obj)}
            />
          </>
        );
      case "checkboxes":
        return (
          <>
            <CheckboxGroup
              label={obj.label}
              options={this.state[`${obj.mappingKey}Options`]}
              row={true}
              values={obj.id}
              handleChange={(e) =>
                this.handleCheckboxChange(
                  e,
                  this.state[`${obj.mappingKey}Options`],
                  obj
                )
              }
            />
          </>
        );
      default:
        return <></>;
    }
  }
  render() {
    return (
      <React.Fragment className="impact-dynamic-filters-container">
        <Grid
          container
          spacing={3}
          columns={15}
          className="impact-dynamic-filters-subcontainer"
        >
          {this.props.filtersList.map((obj, index) => (
            <>
              <Grid
                item
                md={obj.grid || 3}
                key={`decision-dashboard_filter-${obj.label}`}
                className={
                  "impact-filter-list-map impact-filter-list-map" + obj.label
                }
              >
                {this.renderFilters(obj)}
              </Grid>
            </>
          ))}
          {this.props.isFilterNotRequired || this.props.hideButtons ? null : (
            <>
              <Grid
                item
                md={3}
                xl={3}
                display={"flex"}
                alignItems={"flex-end"}
                style={{ paddingTop: "36px" }}
              >
                <Grid container className={"impact-filter-button-container"}>
                  <Grid item xs={12} md={6} className={"impact-filter-button"}>
                    <CustomButton
                      isPrimary={true}
                      variant="contained"
                      height={36}
                      width={104}
                      label="Filter"
                      startIcon={<FilterAltOutlined />}
                      onButtonClick={() => this.props.appliedFilters()}
                    />
                  </Grid>
                  <Grid item xs={12} md={6} className={"impact-reset-button"}>
                    <CustomButton
                      isPrimary={false}
                      variant="outlined"
                      height={36}
                      width={104}
                      label="Reset"
                      startIcon={<Refresh />}
                      onButtonClick={() => this.reset()}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </>
          )}
        </Grid>
      </React.Fragment>
    );
  }
}

DynamicFilters.defaultProps = {
  isDefaultSelectedAll: true,
  isFilterButtonRequired: true,
};
