import React, { useState, useEffect } from "react";
import CustomActionButton from "./CustomActionButton";
import CustomRadioGroup from "./CustomRadioGroup";
import {
  FormControlLabel,
  Grid,
  Switch,
  IconButton,
  Paper,
  Typography,
} from "@mui/material";
import ComponentResolver from "../Resolver/ComponentResolver";
import InputBase from "@mui/material/InputBase";
import CheckboxGroup from "../../../components/CheckboxGroup";
import Box from "@mui/material/Box";
import { useDispatch, useSelector } from "react-redux";
import SelectRenderer from "../../../components/Select/SelectRenderer";
import { groupBy, mapValues } from "lodash";
import DropdownTreeSelectRenderer from "../../../components/dropdownTreeSelect";
import { fetchHierarchyData } from "../DynamicFilters/api";
import CustomRadioButtons from "../CustomComponents/CustomRadioButtons";
import ButtonDropdown from "../CustomComponents/ButtonDropdown";
import TextFieldWithLabel from "../../../components/TextField/TextFieldWithLabel";
import CustomDateRange from "../CustomComponents/CustomDateRange";
import SearchIcon from "@mui/icons-material/Search";
import HorizontalTables from "../CustomComponents/horizontalTables";
import VerticleTables from "../CustomComponents/verticleTables";
import HorizontalCharts from "../CustomComponents/horizontalCharts";
import VerticalCharts from "../CustomComponents/verticalCharts";
import CustomisedCard from "./customisedCard";
import KpiCardComponent from "./KpiCardComponent";
import Calendar from "../../../components/Calendar";
import { Link } from "react-router-dom";

const ActionComponents = (props) => {
  const { data } = props;
  const dispatch = useDispatch();
  const { tableInfo, dependentInfo, formEditedValues, columnsInfo } =
    useSelector((state) => state.home);
  const { formMasterValuesData, formMenuData } = useSelector(
    (state) => state.filter
  );
  const { defaultDependentOn, defaultActionIndex } = data;
  const [selectedOption, setSelectedOption] = useState({});
  const [childComponentsList, setChildComponentsList] = useState([]);
  const [componentsList, setComponentsList] = useState([]);
  const [dropdownSelection, setDropdownSelection] = useState({});
  useEffect(() => {
    if (defaultActionIndex !== null && defaultDependentOn) {
      if (props.data && props.data["isStoreRequired"]) {
        let params = {};
        params[`${defaultDependentOn}`] = true;
        dispatch({
          type: "DEPENDENT_COMPONENTS",
          payload: params,
        });
      }
      setSelectedOption({
        index: defaultActionIndex,
        dependentOn: defaultDependentOn,
      });
    } else {
      if (data["components"]) {
        setSelectedOption({});
        setChildComponentsList([]);
      }
    }
    if (data["components"]) {
      setComponentsList(data.components);
    }
  }, [data.components]);

  useEffect(() => {
    setChildComponentsList([]);
    if (Object.keys(selectedOption).length > 0) {
      if (data.components[selectedOption.index]["childComponents"]) {
        const componentsList = data.components[
          selectedOption.index
        ].childComponents.filter(
          (obj) => obj.dependentOn === selectedOption.dependentOn
        );
        setChildComponentsList(componentsList);
      }
    }
  }, [selectedOption]);

  const handleRadioChange = (e, obj, index) => {
    const { name, value } = e.target;
    if (props.data && props.data["isStoreRequired"]) {
      let params = {};
      obj.options.forEach((option) => {
        if (option.value === value) {
          params[`${value}`] = true;
        } else {
          params[`${option.value}`] = false;
        }
      });
      dispatch({
        type: "DEPENDENT_COMPONENTS",
        payload: params,
      });
    }
    setSelectedOption({
      index: index,
      dependentOn: value,
    });
  };
  const handleCheckboxChange = (e, obj, index) => {
    const { name, value } = e.target;
    if (obj["isStoreRequired"]) {
      let params = {};
      params[`${name}`] = dependentInfo[`${name}`]
        ? !dependentInfo[`${name}`]
        : true;
      dispatch({
        type: "DEPENDENT_COMPONENTS",
        payload: params,
      });
    } else {
      setSelectedOption({
        index: index,
        dependentOn: name,
      });
    }
  };
  const onLabelActionClick = (obj, index) => {
    if (obj.value) {
      if (props.data && props.data["isStoreRequired"]) {
        let params = {};
        params[`${obj.value}`] = dependentInfo[`${obj.value}`]
          ? !dependentInfo[`${obj.value}`]
          : true;
        dispatch({
          type: "DEPENDENT_COMPONENTS",
          payload: params,
        });
      }
      setSelectedOption({
        index: index,
        dependentOn: obj.value,
      });
    }
  };

  const handleSelect = (params, compObj, filter_label,optionsList) => {
    // console.log("params saswati", params);
    let reqParams = { ...dropdownSelection };
    reqParams[`${filter_label}`] = params.selectedItems;
    if (compObj.action) {
      switch (compObj.action.type) {
        case "UPDATE_PLAN_TABLE":
          let metricData = [];
          let payload = {};
          if (compObj.action.source_table_key) {
            metricData = tableInfo[`${compObj.action.source_table_key}`];
            payload[`${compObj.action.parent_table_key}`] = [...metricData];
          } else {
            metricData = tableInfo[`${compObj.action.parent_table_key}`];
            const metricGroupData = mapValues(
              groupBy(metricData, "metric_level")
            );
            const updatedData = metricData.filter((obj) => {
              const newData = metricGroupData[`${obj.metric_level}`];
              if (newData && obj[`ref`] === "WP") {
                if (params.selectedItems.length > 0) {
                  const wpData = newData.filter(
                    (wpObj) => wpObj[`ref`] === params.selectedItems[0].name
                  );
                  let reqObj = { ...wpData[0] };
                  Object.keys(reqObj).forEach((metricName) => {
                    if (metricName !== "ref") {
                      obj[`${metricName}`] = reqObj[`${metricName}`];
                    }
                  });
                } else {
                  Object.keys(obj).forEach((metricName) => {
                    if (metricName !== "ref") {
                      obj[`${metricName}`] = 0;
                    }
                  });
                }

                return obj;
              }
              return obj;
            });
            payload[`${compObj.action.parent_table_key}`] = [...updatedData];
          }

          dispatch({
            type: "TABLE_DATA",
            payload: payload,
          });
          break;
        case "UPDATE_PIVOT_TABLE":
          if (dependentInfo[`Change Axis`]) {
            if (columnsInfo[`tb_plan_enable_pivot_axis`]) {
              const updatedColumns = [];
              const updatedColumnsList = [
                ...columnsInfo[`tb_plan_enable_pivot_axis_copy`],
              ];
              updatedColumnsList.map((parentCol) => {
                let updateReq = { ...parentCol };
                if (parentCol["children"] && params.selectedItems.length > 0) {
                  const childColumnsList = [];
                  const selectedValues = params.selectedItems.map(
                    (obj) => obj.name
                  );
                  const childColumns = parentCol["children"].filter(
                    (childCol) => {
                      if (selectedValues.indexOf(childCol.headerName) !== -1) {
                        return childCol;
                      }
                    }
                  );
                  updateReq[`children`] = childColumns;
                }
                updatedColumns.push(updateReq);
              });
              let columnReq = {};
              columnReq["tb_plan_enable_pivot_axis"] = [...updatedColumns];
              dispatch({
                type: "COLUMN_DATA",
                payload: columnReq,
              });
            }
          } else {
            const newBrandsData = [];
            const pivotData = tableInfo[`${compObj.action.parent_table_key}`];
            const copyData = tableInfo[`${compObj.action.parent_table_key}`];
            const selectedValues = params.selectedItems
              .filter((obj) => obj.isNewBrand === undefined)
              .map((obj) => obj.name);
            const isNewBrand = params.selectedItems.filter(
              (obj) => obj.isNewBrand === true
            );
            const pivotUpdatedData = copyData.filter((obj) => {
              console.log(
                "Selected Values",
                selectedValues,
                selectedValues.includes(obj["class"]),
                obj["class"]
              );
              if (
                selectedValues.includes(obj["class"]) ||
                obj["class"] === "Total"
              ) {
                return obj;
              }
            });

            if (isNewBrand.length > 0) {
              const brandGroupLevel = mapValues(groupBy(copyData, "metric"));
              Object.keys(brandGroupLevel).forEach((metricData) => {
                isNewBrand.forEach((newBrandObj) => {
                  // if(pivotData)
                  if (
                    compObj.action["options"] &&
                    compObj.action["options"].length > 0
                  ) {
                    compObj.action["options"].forEach((nameData) => {
                      let params = {};
                      Object.keys(copyData[0]).forEach((pivotData) => {
                        params[`${pivotData}`] = 0;
                      });
                      params[`metric`] = metricData;
                      params[`ref`] = nameData;
                      params[`class`] = newBrandObj.name;
                      params[`department`] = newBrandObj.department;
                      newBrandsData.push(params);
                    });
                  }
                });
              });
            }
            let payloadReq = {};
            payloadReq[`${compObj.action.parent_table_key}`] = [
              ...pivotUpdatedData,
              // ...copyData,
              ...newBrandsData,
            ];
            console.log("New brands Data", payloadReq);
            dispatch({
              type: "TABLE_DATA",
              payload: payloadReq,
            });
          }

          break;
        case "tab-redirect":
          if (compObj.action.path) {
            // navigate(action.path);
          }

          dispatch({
            type: "TAB_REDIRECT",
            payload: {
              activeTabIndex: compObj.action.activeTabIndex,
              parentTabIndex: compObj.action.parentTabIndex,
              activeTabValue: compObj.action.activeTabValue,
              parentTabValue: compObj.action.parentTabValue,
            },
          });
          if (compObj.action["otherTableUpdate"]) {
            setTimeout(() => {
              let columnReq = {};
              columnReq[`${compObj.action["sourceTableName"]}`] = [
                ...tableInfo[`${compObj.action["otherTableUpdate"]}`],
              ];
              dispatch({
                type: "TABLE_DATA",
                payload: columnReq,
              });
            }, 1000);
          }
          break;
        case "RENDER_KPI_TABLE":
          let payloadReq = {};
          if (params.selectedItems.length > 0) {
            if (params.selectedItems[0].id === compObj.action.key) {
              payloadReq[`${compObj.action.key}`] = true;
              payloadReq[`${compObj.action.otherKey}`] = false;
            } else {
              payloadReq[`${compObj.action.otherKey}`] = true;
              payloadReq[`${compObj.action.key}`] = false;
            }
          }

          dispatch({
            type: "DEPENDENT_COMPONENTS",
            payload: payloadReq,
          });
          break;
        case "UPDATE_INSEASON_PLAN_TABLE":
          let inseasonReq = {};
          inseasonReq[`${compObj.action.parent_table_key}`] = [
            ...tableInfo[`${compObj.action.source}`],
          ];
          dispatch({
            type: "TABLE_DATA",
            payload: inseasonReq,
          });
          break;
        case "UPDATE_USER_SELECTION":
          let selectParams = {};
          const selectedValues = params.selectedItems.map(
            (filterObj) => filterObj.name
          );
          let selectedRows = tableInfo[`${compObj.action.sourceKey}`].filter(
            (filterObj) =>
              selectedValues.indexOf(
                filterObj[`${compObj.action.mappingKey}`]
              ) !== -1
          );
          if (
            compObj.action.otherKey === "Region" &&
            formEditedValues[`${compObj.action.otherKey}`] &&
            formEditedValues[`${compObj.action.otherKey}`].length > 0
          ) {
            if (
              compObj.action.mappingKey === "Cluster" &&
              selectedValues.length === 0
            ) {
              selectedRows = tableInfo[`${compObj.action.sourceKey}`].filter(
                (filterObj) =>
                  formEditedValues[`${compObj.action.otherKey}`].indexOf(
                    filterObj[`${compObj.action.otherKey}`]
                  ) !== -1
              );
            } else {
              selectedRows = tableInfo[`${compObj.action.sourceKey}`].filter(
                (filterObj) =>
                  selectedValues.indexOf(
                    filterObj[`${compObj.action.mappingKey}`]
                  ) !== -1 &&
                  formEditedValues[`${compObj.action.otherKey}`].indexOf(
                    filterObj.Cluster
                  ) !== -1
              );
            }
          } else if (
            compObj.action.otherKey === "Cluster" &&
            formEditedValues[`${compObj.action.otherKey}`] &&
            formEditedValues[`${compObj.action.otherKey}`].length > 0
          ) {
            if (
              compObj.action.mappingKey === "Region" &&
              selectedValues.length === 0
            ) {
              selectedRows = tableInfo[`${compObj.action.sourceKey}`].filter(
                (filterObj) =>
                  formEditedValues[`${compObj.action.otherKey}`].indexOf(
                    filterObj[`${compObj.action.otherKey}`]
                  ) !== -1
              );
            } else {
              selectedRows = tableInfo[`${compObj.action.sourceKey}`].filter(
                (filterObj) =>
                  selectedValues.indexOf(
                    filterObj[`${compObj.action.mappingKey}`]
                  ) !== -1 &&
                  formEditedValues[`${compObj.action.otherKey}`].indexOf(
                    filterObj.Cluster
                  ) !== -1
              );
            }
          } else if (
            formEditedValues[`${compObj.action.otherKey}`] &&
            formEditedValues[`${compObj.action.otherKey}`].length > 0
          ) {
            selectedRows = tableInfo[`${compObj.action.sourceKey}`].filter(
              (filterObj) =>
                selectedValues.indexOf(
                  filterObj[`${compObj.action.otherKey}`]
                ) !== -1
            );
          }
          selectParams[`${compObj.action.destinationKey}`] = [...selectedRows];
          let formEditing = {};
          formEditing[`${compObj.action.mappingKey}`] = selectedValues;
          dispatch({
            type: "TABLE_DATA",
            payload: selectParams,
          });
          dispatch({
            type: "FORM_EDITED_VALUES",
            payload: formEditing,
          });
        case "IS_STORE_REQUIRED":
          let dropdownValue = params.selectedItems.map(
            (filterObj) => filterObj.name
          );
          if (dropdownValue.length > 0) {
            let dropdownParams = {};
            let value = dropdownValue[0];
            if ( compObj.action.toggleOptions && compObj.action.toggleOptions.length > 0) {
              compObj.action.toggleOptions.forEach((obj) => {
                if (value == obj) {
                  dropdownParams[`${value}`] = true;
                } else {
                  dropdownParams[`${obj}`] = false;
                }
              });
            } else {
              dropdownParams[`${value}`] = dependentInfo[`${value}`]
                ? !dependentInfo[`${value}`]
                : true;
            }
            let formEditing = {};
            formEditing[`${compObj.form_label}`] = params.selectedItems;
            dispatch({
              type: "FORM_EDITED_VALUES",
              payload: formEditing,
            });
            dispatch({
              type: "DEPENDENT_COMPONENTS",
              payload: dropdownParams,
            });
          }
        case "IS_FORM_EDIT_REQUIRED":
          let formEditingParams = {};
          formEditingParams[`${compObj.form_label}`] = params.selectedItems;
          dispatch({
            type: "FORM_EDITED_VALUES",
            payload: formEditingParams,
          });
        case "DISPLAY_BASED_ON_SELECTION":
            let selectedValue = params.selectedItems.map(
              (filterObj) => filterObj.name
            );
            if (selectedValue.length > 0) {
              let dropdownParams = {};
              let value = selectedValue[0];
              if ( optionsList.length > 0) {
                optionsList.forEach((obj) => {
                  if (value == obj.value) {
                    dropdownParams[`${value}`] = true;
                  } else {
                    dropdownParams[`${obj.value}`] = false;
                  }
                });
              } else {
                dropdownParams[`${value}`] = dependentInfo[`${value}`]
                  ? !dependentInfo[`${value}`]
                  : true;
              }
              if(compObj.action.hideOtherDependents){
                compObj.action.hideOtherDependents.forEach(obj=>{
                  dropdownParams[`${obj}`] = false;
                })
              }
              dispatch({
                type: "DEPENDENT_COMPONENTS",
                payload: dropdownParams,
              });
            }
        case "UPDATE_TABLE_COLUMNS":
          if (columnsInfo[`${compObj.action.source}`]) {
            const updatedColumns = [];
            const updatedColumnsList = [
              ...columnsInfo[`${compObj.action.destination}`],
            ];
            updatedColumnsList.map((parentCol) => {
              let updateReq = { ...parentCol };
              if (parentCol["children"] ) {
                let childColumnsList =parentCol["children"].map(columnObj=> columnObj.headerName)
                if(params.selectedItems.length > 0){
                  const selectedValues = params.selectedItems.map(
                    (obj) => obj.name
                  );
                  selectedValues.forEach(selectedObj=>{
                    if(childColumnsList.indexOf(selectedObj) === -1){
                      compObj.action.options.forEach(colOb=>{
                        parentCol["children"].push({
                          headerName: ( colOb ? `${colOb}` : `${selectedObj}` ),
                          "field":( colOb  ? `${parentCol.field}_${selectedObj}_${colOb}` : `${parentCol.field}_${selectedObj}` )
                        })
                      })
                    }
                    
                  })
                  optionsList.forEach(optionObj=>{
                    let selectedOptionIndex = selectedValues.indexOf(optionObj.value)
                    if(selectedOptionIndex === -1){
                      let optionIndex = childColumnsList.indexOf(optionObj.value);
                      if(optionIndex !== -1){
                        updateReq["children"].splice(optionIndex,2)
                      } 
                    }
                    
                  })
                }else{
                  optionsList.forEach(optionObj=>{
                    console.log(childColumnsList.indexOf(optionObj.value))
                    let optionIndex = childColumnsList.indexOf(optionObj.value);
                    if(optionIndex !== -1){
                      updateReq["children"].splice(optionIndex,2)
                    } 
                  })
                }
                
                
              }
              updatedColumns.push(updateReq);
            });
            let columnReq = {};
            columnReq[`${compObj.action.source}`] = [...updatedColumns];
            dispatch({
              type: "COLUMN_DATA",
              payload: columnReq,
            });
          }
    
              break;
        default:
          break;
      }
    }
    setDropdownSelection(reqParams);
    dispatch({
      type: "GET_DROPDOWN_SELECTION_DATA",
      payload: reqParams,
    });
  };
  const handleTextFieldChange  =(e,field)=>{
    const { name , value } = e.target;
    if(field['isStoreRequired']){
      let params = {}
      params[`${name}`] = value;
      dispatch({
        type: "FORM_EDITED_VALUES",
        payload: params,
      });
    }
    
  }
  const renderActionComponents = (componentInfo, index) => {
    const { type } = componentInfo;
    switch (type) {
      case "radio":
        return <CustomRadioButtons data={componentInfo} />;
      case "date-range-picker":
        return <CustomDateRange data={componentInfo} />;
      case "textField":
        return (
          <>
            {componentInfo.info.map((field) => (
              <TextFieldWithLabel
                type={field.type}
                label={field.label}
                width={field.width}
                defaultValue={ field.defaultValue}
                name={field.name}
                onChange={(e)=>{
                  handleTextFieldChange(e,field)

                }}
              />
            ))}
          </>
        );
      case "CustomRadioGroup":
        return (
          <>
            <CustomRadioGroup
              label={componentInfo.label}
              options={componentInfo.options}
              row={true}
              values={1}
              handleChange={(e) => handleRadioChange(e, componentInfo, index)}
              checkedValue={selectedOption.dependentOn}
              horizontalView={componentInfo.horizontalView}
            />
          </>
        );
        break;
      case "Checkboxes":
        return (
          <>
            <CheckboxGroup
              label={componentInfo.label}
              options={componentInfo.options}
              row={!componentInfo.column}
              values={1}
              handleChange={(e) =>
                handleCheckboxChange(e, componentInfo, index)
              }
            />
          </>
        );
        break;
      case "bottomActionButton":
        <CustomActionButton
          data={componentInfo}
          itemIndex={index}
          otherInfo={props.data.otherInfo}
        />;
      case "actionButton":
        return (
          <>
            {componentInfo[`dependentOn`] ? (
              <>
                {dependentInfo[`${componentInfo[`dependentOn`]}`] ? (
                  <>
                    <CustomActionButton
                      data={componentInfo}
                      itemIndex={index}
                      otherInfo={props.data.otherInfo}
                    />
                  </>
                ) : null}
              </>
            ) : (
              <>
                <CustomActionButton
                  data={componentInfo}
                  itemIndex={index}
                  otherInfo={props.data.otherInfo}
                />
              </>
            )}
          </>
        );
      case "buttonDropdown":
        return <ButtonDropdown data={componentInfo} />;
      case "forms":
        return (
          <>
            <ComponentResolver
              pageObject={{ components: componentInfo.components }}
            />
          </>
        );
      case "filters":
        return (
          <>
            <ComponentResolver pageObject={{ components: [componentInfo] }} />
          </>
        );
      case "search":
        return (
          <Box sx={{ display: { xs: "none", md: "block" } }}>
            <Paper
              component="form"
              sx={{
                p: 0,
                display: "flex",
                alignItems: "center",
                width: "100%",
              }}
            >
              <InputBase
                sx={{ ml: 1, flex: 1, padding: 0 }}
                placeholder={`Search`}
                inputProps={`search`}
                onChange={(e) => () => {}}
              />
              <IconButton type="submit" sx={{ p: "10px" }} aria-label="search">
                <SearchIcon />
              </IconButton>
            </Paper>
          </Box>
        );
      case "label":
        return (
          <>
            {componentInfo.options.map((obj, childIndex) => (
              <>
                <div
                  onClick={() => {
                    onLabelActionClick(obj, index);
                  }}
                  style={{ cursor: "pointer", color: "#0255AF" }}
                >
                  {obj.label}
                </div>
              </>
            ))}
          </>
        );
      case "text":
        return (
          <div style={componentInfo.style}>
            <label>{componentInfo.name} </label>
          </div>
        );
      case "table":
        return (
          <div style={componentInfo.style}>
            <VerticleTables tables={componentInfo.info} />;
          </div>
        );
      case "verticalCharts":
        return <VerticalCharts charts={componentInfo.info} />;
      case "select":
        const optionsList = formMasterValuesData.filter((obj) => {
          if (parseInt(obj.parent_id) === componentInfo.filter_id) {
            obj.label = obj.name;
            obj.value = obj.name;
            return obj;
          }
        });
        const defaultValue = formMasterValuesData.filter((obj) => {
          if (parseInt(obj.parent_id) === componentInfo.filter_id) {
            if (obj.is_default_value === "Y") {
              obj.label = obj.name;
              obj.value = obj.name;
              return obj;
            }
          }
        });
        const formDetails =
          formMenuData.filter(
            (obj) => parseInt(obj["categoryid"]) === componentInfo.filter_id
          )[0] || {};
        const filter_label = componentInfo.isFormFilters
          ? formDetails.label
          : componentInfo.form_label;
        return (
          <>
            <Grid item xs>
              <div style={componentInfo.style}>
                <SelectRenderer
                  options={optionsList}
                  isMandatory={false}
                  isMulti={
                    componentInfo.isFormFilters
                      ? formDetails.type === "single-select"
                        ? false
                        : true
                      : false
                  }
                  filterLabel={filter_label}
                  width={componentInfo['isWidthNotRequired'] ? "" : "18rem"}
                  updateSelected={(params) => {
                    handleSelect(
                      params,
                      componentInfo,
                      componentInfo.filter_id,
                      optionsList
                    );
                  }}
                  selectedItems={
                    dropdownSelection[`${componentInfo.filter_id}`]
                      ? dropdownSelection[`${componentInfo.filter_id}`]
                      : defaultValue
                  }
                  {...componentInfo}
                />
              </div>
            </Grid>
          </>
        );
      case "switch":
        return (
          <>
            {componentInfo.label}{" "}
            <FormControlLabel
              control={<Switch defaultChecked />}
              label={componentInfo.endLabel}
            />
          </>
        );
      case "dropdown-tree-select":
        const dropdownList = fetchHierarchyData(componentInfo.filter_id, {
          data: formMasterValuesData,
          type: "dropdown-tree-select",
        });
        return (
          <>
            <DropdownTreeSelectRenderer
              data={dropdownList}
              filterLabel={componentInfo.form_label}
              placeholder={componentInfo.placeholder}
              isMandatory={componentInfo.required}
              {...componentInfo}
              // onChange={this.onChange} onAction={this.onAction} onNodeToggle={this.onNodeToggle}
            />
          </>
        );
      case "buttonDropdown":
        return (
          <>
            <ComponentResolver
              pageObject={{ components: componentInfo.components }}
            />
          </>
        );
      case "title":
        return (
          <>
          {componentInfo["redirectToLink"] ? (
            <>
            <Link to={`${process.env.REACT_APP_BASE_ROUTE}${componentInfo.redirectToLink}`}  > {componentInfo.redirectLabel}</Link>
            </>
          ):null }
                
            <h3>
              {componentInfo[`dependentOn`] ? (
                <>
                  {dependentInfo[`${componentInfo[`dependentOn`]}`]
                    ? componentInfo.title
                    : null}
                </>
              ) : (
                <>{componentInfo.title}</>
              )}
            </h3>
          </>
        );
      case "card":
          return (
            <Typography gutterBottom variant="h5">
              {componentInfo?.title}
              {componentInfo.info?.map((item) => (
                <CustomisedCard item={item} />
              ))}
            </Typography>
          );
      case "horizontalCharts":
            return(
              <>
              {componentInfo[`dependentOn`] ? (
                <>
                  {dependentInfo[`${componentInfo[`dependentOn`]}`]
                    ? <HorizontalCharts charts={componentInfo.info} {...componentInfo} />
                    : null}
                </>
              ) : (
                <><HorizontalCharts charts={componentInfo.info} {...componentInfo} /></>
              )}
              </>
            ) 
      case "KPICard":
        return <KpiCardComponent data={componentInfo} />;
      case "calendar":
        return <Calendar onCalEventClick={null} dataObj={componentInfo} />;   
      default:
        break;
    }
  };

  return (
    <div>
      <Grid
        container
        spacing={1}
        className={"impact-action-components-container"}
      >
        {componentsList.map((obj, index) => (
          <>
            {obj.size ? (
              <>
                {obj[`dependentOn`] ? (
                  <>
                    {dependentInfo[`${obj[`dependentOn`]}`] ? (
                      <Grid
                        item
                        xs={12}
                        md={obj.size}
                        xl={obj.size}
                        lg={obj.size}
                        className={
                          "impact-action-components-container-with-size"
                        }
                      >
                        {renderActionComponents(obj, index)}
                      </Grid>
                    ) : null}
                  </>
                ) : (
                  <>
                    <Grid
                      item
                      md={obj.size}
                      xl={obj.size}
                      lg={obj.size}
                      className={"impact-action-components-container-with-size"}
                    >
                      {renderActionComponents(obj, index)}
                    </Grid>
                  </>
                )}
              </>
            ) : (
              <>
                {obj[`dependentOn`] ? (
                  <>
                    {dependentInfo[`${obj[`dependentOn`]}`] ? (
                      <Grid
                        item
                        xs
                        className={
                          "impact-action-components-container-without-size"
                        }
                      >
                        {renderActionComponents(obj, index)}
                      </Grid>
                    ) : null}
                  </>
                ) : (
                  <>
                    <Grid
                      item
                      xs
                      className={
                        "impact-action-components-container-without-size"
                      }
                    >
                      {renderActionComponents(obj, index)}
                    </Grid>
                  </>
                )}
              </>
            )}
          </>
        ))}
      </Grid>
      {childComponentsList.length > 0 ? (
        <>
          {/* <div className="content-spacer"></div> */}
          <Grid spacing={1}>
            <ComponentResolver
              pageObject={{ components: childComponentsList }}
            />
          </Grid>
        </>
      ) : null}
    </div>
  );
};

export default ActionComponents;
