import React, { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";
import DynamicFilters from "./DynamicFilters";
import CustomCard from "../../components/CustomCard";
import CustomAccordion from "../../components/CustomAccordion";
import FiltersHead from "./FiltersHead";
import moment from "moment";
import Filters from "../../components/FilterChips";
import "../MainCss.scss";

import { fetchFilterDataAction } from "./action";
import SnackBar from "../../components/SnackBar";
import { useDispatch, useSelector } from "react-redux";

const styles = (theme) => ({
  headerCard: {
    ...theme.content.card,
    width: "100%",
    height: "100%",
    overflow: theme.content.overflow.visible,
  },
  hierarchyHeaderCard: {
    ...theme.content.card,
    width: "100%",
    height: "100%",
    padding: "1%",
    display: "flex",
    justifyContent: "space-between",
    overflow: theme.content.overflow.visible,
  },
  hierarchyCard: {
    ...theme.content.card,
    maxWidth: 280,
    minWidth: 280,
    height: "100%",
    padding: "2%",
    overflow: theme.content.overflow.visible,
  },
  filterPage: {
    ...theme.content.card,
    width: "100%",
    height: "100vh",
    paddingTop: "0.75%",
    overflow: theme.content.overflow.visible,
  },
  container: {
    ...theme.content.flexStyles.flexRow,
    ...theme.content.flexStyles.flexAlignBetweenCenter,
  },
});
const useStyles = makeStyles(styles);

const FiltersList = (props) => {
  const {
    openLoader,
    closeLoader,
    screenName,
    filter_id,
    isFilterButtonNotRequired,
    filter_label,
  } = props;
  // const { isRadio, radioData, isClusterFilter, clusterFilterOptions } =
  // 	isFilterNotRequired;
  const dispatch = useDispatch();
  const { filterMenuData, filterMasterValuesData } = useSelector(
    (state) => state.filter
  );
  const { tableInfo, dependentInfo, formEditedValues, columnsInfo } =
  useSelector((state) => state.home);
  const classes = useStyles();
  const [filtersExpanded, setFiltersExpanded] = useState(false);
  const [filterMenu, setFilterMenu] = useState([]);
  const [filterMasterValues, setFilterMasterValues] = useState([]);
  const [dynamicFiltersProps, setDynamicFiltersProps] = useState([]);
  const [dynamicFiltersOptions, setDynamicFiltersOptions] = useState([]);
  const [independentApiCalls, setIndependentApiCalls] = useState([]);
  const [filterData, setFilterData] = useState({});
  const [selectedFilterChips, setSelectedFilterChips] = useState({});
  const [selectedFilter, setSelectedFilter] = useState("");
  const [displayMessage, setDisplayMessage] = useState(false);
  const [message, setMessage] = useState(null);
  const [modelFilteredData, setModelFilteredData] = useState([]);

  useEffect(() => {
    if (filterMenuData.length === 0) {
      openLoader();
      dispatch(
        fetchFilterDataAction({
          model_names: ["tb_filter_master_copy", "tb_filter_master_values"],
        })
      );
    }
  }, []);

  useEffect(() => {
    const filter_data = filterMenuData.filter(
      (obj) => parseInt(obj["filter_id"]) === filter_id
    );
    setFilterMenu([...filter_data]);
    setFilterMasterValues([...filterMasterValuesData]);
  }, [filterMenuData, filterMasterValuesData, filter_id]);

  const reloadFilters = async () => {
    const filterObj = {};
    await filterMenu.forEach((obj) => {
      if (obj.type === "datetime" || obj.type === "date-range") {
        filterObj[`${obj.master_category}`] = [null, null];
      } else if (obj.type === "date") {
        filterObj[`${obj.label}`] = [new Date(moment())];
      } else {
        const newData = filterMasterValues.filter(
          (childObj) =>
            parseInt(childObj.parent_id) === parseInt(obj.categoryid) &&
            childObj.is_default_value === "Y"
        );
        newData.filter((obj) => {
          obj["label"] = obj.name;
          obj["value"] = obj.name;
        });
        filterObj[`${obj.master_category}`] = newData;
        // filterObj[`${obj.master_category}`] = [];
      }
    });
    setFilterData(filterObj);
    setSelectedFilterChips({});
  };
  useEffect(() => {
    reloadFilters();
  }, [filterMasterValues]);

  useEffect(() => {
    if (Object.keys(filterData).length > 0) {
      const filtersPropsList = [];
      const filtersOptionsList = {};
      const independentApis = [];
      filterMenu.forEach((obj) => {
        let isDependentOn = filterMenu.filter(
          (filterObj) => filterObj.is_dependent_on === obj.id
        );
        filtersPropsList.push({
          type: obj.type,
          placeholder: `Select ${obj.master_category}`,
          state: filterData[`${obj.master_category}`],
          mappingKey: obj.master_category,
          label: obj.label,
          required: obj.is_required === "TRUE",
          is_dependent_on: obj.is_dependent_on,
          id: obj.categoryid,
          infotipEnabled: obj.infotip_enabled === "1",
          infotip: obj.infotip,
          isDependentOn,
          grid: obj.grid,
          ...obj
        });
        filtersOptionsList[`${obj.master_category}Options`] = [];
        if (obj.is_dependent === "FALSE") {
          independentApis.push({
            optionsMappingKey: obj.master_category,
            UrlName: obj.categoryid,
            type: obj.type,
          });
        }
      });
      setDynamicFiltersProps(filtersPropsList);
      setDynamicFiltersOptions(filtersOptionsList);
      setIndependentApiCalls(independentApis);
    }
  }, [filterData]);
  const updateState = (params,dropdownOptions) => {
    // console.log("change",options,params,dynamicFiltersProps.filter(obj=> obj.mappingKey === Object.keys(params)[0] && obj['is_store_required'] === 'TRUE'))
    const formEditedInfo = dynamicFiltersProps.filter(obj=> obj.mappingKey === Object.keys(params)[0] && obj['is_store_required'] === 'TRUE');
    
    let reqParams = {
      ...filterData,
      ...params,
    };
    setFilterData(reqParams);
    props.updateParentState(reqParams);
    if(formEditedInfo.length > 0 ){
      const dropdownName = Object.keys(params)[0];
      let formEditing = {};
      let dropdownParams = {}
      let value = params[`${dropdownName}`].map(obj=> obj.value)[0];
      formEditing[`${Object.keys(params)[0]}`] = params[`${dropdownName}`].map(obj=> obj.value)[0];
      if(dropdownOptions){
        dropdownOptions.forEach(obj=> {
          if(value == obj.value){
            dropdownParams[`${obj.value}`] =  true;
          }else{
            if(dependentInfo[`${obj.value}`]){
              dropdownParams[`${obj.value}`] = false;
            }
          }
          // dropdownParams[`${value}`] = dependentInfo[`${value}`] ? !dependentInfo[`${value}`] : true;
        })
      }else{
        dropdownParams[`${value}`] = dependentInfo[`${value}`] ? !dependentInfo[`${value}`] : true;

      }
      dispatch({
        type: "FORM_EDITED_VALUES",
        payload: formEditing,
      });
      dispatch({
        type: "DEPENDENT_COMPONENTS",
        payload: dropdownParams,
      });
    }
   
  };
  const onReset = () => {
    reloadFilters();
    setFiltersExpanded(false);
    let params = {};
      params[`filter-button-click`] = false;
      params[`default-key`] = true;
      dispatch({
        type: "DEPENDENT_COMPONENTS",
        payload: params,
      });
    props.onReset();
  };
  const appliedFiltersAction = async () => {
    props.appliedFilters(filterData);
    let reqObj = {};
    Object.keys(filterData).filter((obj, index) => {
      if (filterData[`${obj}`].length > 0) {
        reqObj[`${obj}`] = {
          label: obj,
          section: filterData[`${obj}`].map((obj) => obj.value),
        };
      }
    });
    let params = {};
      params[`filter-button-click`] = !dependentInfo[`filter-button-click`]
      params[`default-key`] = !dependentInfo[`default-key`]
      dispatch({
        type: "DEPENDENT_COMPONENTS",
        payload: params,
      });
    setSelectedFilterChips(reqObj);
    setFiltersExpanded(false);
  };

  const handleMessageClose = () => {
    setDisplayMessage(false);
    setMessage(null);
  };
  return (
    <div className={"impact-filter-container"}>
      {/* <LoadingOverlay loader={loader}> */}
      {displayMessage && (
        <SnackBar
          message={message}
          variant="warning"
          handleClose={handleMessageClose}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        />
      )}

      {isFilterButtonNotRequired ? (
        <>
        { props.filterBlockNotRequired ? (
          <>
            {dynamicFiltersProps.length > 0 && (
                <DynamicFilters
                  filtersList={dynamicFiltersProps}
                  options={dynamicFiltersOptions}
                  hierarchyApiCalls={independentApiCalls}
                  openLoader={props.openLoader}
                  closeLoader={props.closeLoader}
                  updateParentState={updateState}
                  appliedFilters={appliedFiltersAction}
                  onReset={onReset}
                  screenName={screenName}
                  filterMasterValues={filterMasterValues}
                  isFilterNotRequired={true}
                />
              )}
          </>
        ):(
          <>
            <CustomCard>
          <div
            style={{
              marginLeft: "25px",
              marginTop: "5px",
              marginRight: "25px",
            }}
            className={"impact-filters-withoutbutton"}
          >
            <div className="filter-container">
              <h2>{filter_label}</h2>
              {dynamicFiltersProps.length > 0 && (
                <DynamicFilters
                  filtersList={dynamicFiltersProps}
                  options={dynamicFiltersOptions}
                  hierarchyApiCalls={independentApiCalls}
                  openLoader={props.openLoader}
                  closeLoader={props.closeLoader}
                  updateParentState={updateState}
                  appliedFilters={appliedFiltersAction}
                  onReset={onReset}
                  screenName={screenName}
                  filterMasterValues={filterMasterValues}
                  isFilterNotRequired={true}
                />
              )}
            </div>
            <div className="spacer" />
            <hr
              style={{
                marginRight: "1.25%",
                borderTop: "1px solid #CCC",
              }}
            ></hr>
          </div>
        </CustomCard>
          </>
        ) }
        
        </>
      ) : (
        <>
          {props.expand ? (
            <Grid container spacing={2} className={"impact-filters-withbutton"}>
              <Grid
                item
                xs={12}
                className={"impact-filters-withbutton-subcontainer"}
              >
                {dynamicFiltersProps.length > 0 ? (
                  <DynamicFilters
                    hideButtons={props.hideButtons}
                    filtersList={dynamicFiltersProps}
                    options={dynamicFiltersOptions}
                    hierarchyApiCalls={independentApiCalls}
                    openLoader={props.openLoader}
                    closeLoader={props.closeLoader}
                    updateParentState={updateState}
                    appliedFilters={appliedFiltersAction}
                    onReset={onReset}
                    screenName={screenName}
                    filterMasterValues={filterMasterValues}
                    isFilterNotRequired={false}
                    isFilterButtonNotRequired={isFilterButtonNotRequired}
                  />
                ) : null}
              </Grid>
            </Grid>
          ) : (
            <Grid
              container
              spacing={2}
              className={"impact-dynamicfilter-without-expand"}
            >
              <Grid
                item
                xs={12}
                className={"impact-dynamicfilter-without-expand-subcontainer"}
              >
                <CustomCard cardStyles={classes.headerCard}>
                  {dynamicFiltersProps.length > 0 ? (
                    <>
                      <CustomAccordion
                        summary={
                          <FiltersHead
                            filtersExpanded={props.expand || filtersExpanded}
                            setFiltersExpanded={setFiltersExpanded}
                            screenName={screenName}
                          />
                        }
                        details={
                          <DynamicFilters
                            hideButtons={props.hideButtons}
                            filtersList={dynamicFiltersProps}
                            options={dynamicFiltersOptions}
                            hierarchyApiCalls={independentApiCalls}
                            openLoader={props.openLoader}
                            closeLoader={props.closeLoader}
                            updateParentState={updateState}
                            appliedFilters={appliedFiltersAction}
                            onReset={onReset}
                            screenName={screenName}
                            filterMasterValues={filterMasterValues}
                            isFilterNotRequired={false}
                            isFilterButtonNotRequired={
                              isFilterButtonNotRequired
                            }
                          />
                        }
                        expanded={props.expand || filtersExpanded}
                      />
                    </>
                  ) : null}
                </CustomCard>
              </Grid>
            </Grid>
          )}

          {!filtersExpanded && Object.keys(selectedFilterChips).length > 0 && (
            <Filters filtersSummary={selectedFilterChips} />
          )}
        </>
      )}
      {/* </LoadingOverlay> */}
    </div>
  );
};
export default FiltersList;
