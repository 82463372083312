import React, { Component,useEffect,useState } from "react";
import makeAnimated from "react-select/animated";
import MySelect from "./CustomReactSelect";
import { components } from "react-select";

const ValueContainer = ({ children, ...props }) => {
  let [values, input] = children;
  if (Array.isArray(values)) {
    const plural = values.length === 1 ? "" : "s";
    if(values.length === 1){
      values = `${values[0]['props']['children']}`;
    }else{
      values = `${values.length} item${plural} selected`;
    }
  }

  return (
    <components.ValueContainer {...props}>
      {values}
      {input}
    </components.ValueContainer>
  );
};
const Option = (props) => {
  return (
    <div>
      <components.Option {...props}>
        <input
          type="checkbox"
          checked={props.isSelected}
          onChange={() => null}
        />{" "}
        <label>{props.label}</label>
      </components.Option>
    </div>
  );
};

const MultiValue = (props) => (
  <components.MultiValue {...props}>
    <span>{props.data.label}</span>
  </components.MultiValue>
);

const animatedComponents = makeAnimated();

 const CustomSelectDropdown = (props) => {

  const [optionSelected, setOptionSelected] = useState(props.selectedValues)
  const [options, setOptions] = useState(props.options)
  useEffect(() => {
    setOptionSelected(props.selectedValues)
  }, [props.selectedValues])
  useEffect(() => {
    setOptions(props.options)
  }, [props.options])

  const handleChange = (selected) => {
   setOptionSelected(selected)
    props.onChange()
  };
  return (
    <>
          <MySelect
              options={props.options}
              closeMenuOnSelect={false}
              hideSelectedOptions={false}
              components={{ Option, MultiValue, animatedComponents, ValueContainer }}
              onChange={handleChange}
              allowSelectAll={true}
              value={optionSelected}
              {...props}
            />
    </>
  )
}

export default CustomSelectDropdown;

