import callApi from "../../utils/callApi";

export const fetchConfigData = (filterProperty, reqObj) => {
	let url = encodeURIComponent(filterProperty);
	return callApi
		.post(`get_page_json?pageid=${url}`)
		.then((res) => {
			return res.data;
		})
		.catch((err) => {
			return err;
		});
};

export const fetchHierarchyData = (filterProperty, reqObj) => {
	let url = encodeURIComponent(filterProperty);
	return callApi
		.post(`get_data_model?model_name=${url}`)
		.then((res) => {
			return res.data;
		})
		.catch((err) => {
			return err;
		});
};

export const getMultipleModelsData = (reqParams) => {
	return callApi
		.post(`get_mutliple_data_models`, reqParams)
		.then((res) => {
			return res.data;
		})
		.catch((err) => {
			return err;
		});
};

export const getDataModelWithFilter = (reqParams) => {
	return callApi
		.post(`get_data_model_with_filter`, reqParams)
		.then((res) => {
			console.log(res, res.data);
			return res.data;
		})
		.catch((err) => {
			return err;
		});
};

export const fetchFilterDataAction = (reqParams)=> dispatch  => {
	return callApi.post(`get_mutliple_data_models`, reqParams).then((res) => {
		const { tb_filter_master_copy, tb_filter_master_values } = res.data;
		console.log("resulst",res.data)
		dispatch({ type: "UPDATE_FILTER_DATA" , payload: {
			tb_filter_master_copy, tb_filter_master_values
		} });
			return res.data;
		})
		.catch((err) => {
			return err;
		});
};
export const fetchFormDataAction = (reqParams)=> dispatch  => {
	return callApi.post(`get_mutliple_data_models`, reqParams).then((res) => {
		const { tb_form_master, tb_form_master_values } = res.data;
		console.log("resulst",res.data)
		dispatch({ type: "UPDATE_FORM_DATA" , payload: {
			tb_form_master, tb_form_master_values
		} });
			return res.data;
		})
		.catch((err) => {
			return err;
		});
};

export const fetchData = (url,reqParams) => {
	return callApi
		.get(url, reqParams)
		.then((res) => {
			return res.data;
		})
		.catch((err) => {
			return err;
		});
};
export const deleteData = (url,reqParams) => {
	return callApi
		.post(url, reqParams)
		.then((res) => {
			return res.data;
		})
		.catch((err) => {
			return err;
		});
};