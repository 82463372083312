import React, { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import { fetchConfigData } from "./action";
import LoadingOverlay from "../../components/LoadingOverlay";
import ComponentResolver from "./Resolver/ComponentResolver";
import "../MainCss.scss";
import _ from 'lodash';
import { addNotifications } from "./data";

const Index = ({ pageConfig }) => {
  const [loader, setLoader] = useState(false);
  const [currentPageObj, setCurrentPageObj] = useState({});

  const getConfigData = async () => {
    setLoader(true);
    const pageId = pageConfig?.json_id;
    const apidata = await fetchConfigData(pageId);
    console.log("ThisPageConfig", pageConfig,apidata,apidata && apidata["notifications"]);
    if(apidata["notifications"] && Object.keys(apidata["notifications"]).length > 0 ){
      addNotifications(apidata['notifications'])
    }
    setCurrentPageObj(apidata);
    setLoader(false);
  };

  useEffect(() => {
    setCurrentPageObj({});
    getConfigData();
  }, [pageConfig]);

  let productThem = "impact-plan-smart-them";

  return (
    <LoadingOverlay
      loader={loader}
      className={productThem + " impact-loading-overlay"}
    >
      <Grid container spacing={2} className="impact-home-container">
        <Grid
          item
          xs={12}
          className={productThem + " impact-home-subcontainer"}
        >
          {Object.keys(currentPageObj).length > 0 ? (
            <>
              <ComponentResolver
                pageObject={currentPageObj}
                redirectFrom="Home"
              />
            </>
          ) : null}
        </Grid>
      </Grid>
    </LoadingOverlay>
  );
};

export default Index;
