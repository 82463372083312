import React from "react";
import { Typography, TextField } from "@mui/material";

const CustomKpiCard = (props) => {
  const { data } = props;

  let entries = Object.entries(data);

  return (
    <div style={{ padding: 10 }}>
      <Typography
        style={{
          marginBottom: 5,
          fontSize: "1rem",
          fontWeight: 500,
        }}
      >
        {data.header}
      </Typography>
      {entries.map((obj) => (
        <>
          {obj[0] !== "header" && (
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                verticalAlign: "center",
                paddingTop: 5,
                paddingBottom: 5,
              }}
            >
              <Typography style={{ fontSize: "0.75rem" }}>{obj[0]}</Typography>
              {obj[0] === "TY Plan" && props.values.editableField ? (
                <TextField
                  id="outlined-number"
                  size="small"
                  defaultValue={obj[1]}
                  inputProps={{
                    style: {
                      height: "10px",
                      width: 80,
                      color: "#0055AF",
                      fontSize: "0.75rem",
                      fontWeight: "600",
                      textAlign: "right",
                      paddingRight: 3,
                    },
                  }}
                />
              ) : (
                <Typography
                  style={{ fontSize: "0.75rem" }}
                  color="#0055AF"
                  fontWeight="600"
                >
                  {obj[1]}
                </Typography>
              )}
            </div>
          )}
        </>
      ))}
    </div>
  );
};

export default CustomKpiCard;
