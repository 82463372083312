import React from 'react';

import styles from '../index.module.css';

export default function CalendarNavigation({ onPrevClick, onNxtClick, navigationText }) {
  const arrowStyle = { fontSize: '22px' };
  return (
    <div className={`${styles['navigation-text-container']}`}>
      <div className={`${styles['navigation-arrow']}`} onClick={onPrevClick}>
        <i className="fa fa-angle-left" style={arrowStyle} />
      </div>
      <span className={`${styles['navigation-text']}`}>{navigationText()}</span>
      <div className={`${styles['navigation-arrow']}`} onClick={onNxtClick}>
        <i className="fa fa-angle-right" style={arrowStyle} />
      </div>
    </div>
  );
}
