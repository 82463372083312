import React from "react";
import { Switch } from "@mui/material";

const CellSwitchField = (props) => {
  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <Switch
        defaultChecked={props.value}
      />
    </div>
  );
};

export default CellSwitchField;
