import axios from "axios";

const callApi = axios.create({
	baseURL: `${process.env.REACT_APP_API_ENDPOINT}`,
	withCredentials: true,
});

callApi.interceptors.request.use(
	function (config) {
		return config;
	},
	function (error) {
		console.log("request fails with error", error.message);
		return Promise.reject(error);
	},
);

callApi.interceptors.response.use(
	function (res) {
		return res;
	},
	async function (error) {
		const {
			response: { status },
		} = error;
		console.log(error, status);
		console.log("response fails with error", error.message, error.config);
		// return error;
	},
);

export default callApi;
